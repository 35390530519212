import React from 'react'; 

import { useHistory } from 'react-router-dom';


function Transfer1(){
  const history = useHistory();


if (!sessionStorage.getItem('auth-token')) {
  console.log('no auth token set');
  history.push('/');
  //do something like redirect to login page
} else {
  const authToken = 'piggyalphalimited2209827462713182219@quiqw0okqp-pl';
  if (sessionStorage.getItem('auth-token') === authToken) {
      console.log('good token. Log in.')
      //do something like redirect to todo page
  } else {
      console.log('bad token.')
      history.push('/');
      //do something like redirect to login page
  }
}
    return (
        <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-row flex-column-fluid page">
          <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
            <div id="kt_header" className="header flex-column header-fixed" style={{backgroundColor: '#E4F9FF'}}>
              <div className="header-top">
                <div className="container">
                  <div className="d-none d-lg-flex align-items-center mr-3">
                    <a href="dashboard" className="mr-20">
                      <img alt="Logo" src="assets/media/logos/piggy-1.png" className="max-h-35px" />
                    </a>
                  </div>
                  <div className="topbar" style={{backgroundColor: '#E4F9FF'}}>
                    <div className="topbar-item" style={{backgroundColor: '#E4F9FF'}}>
                      <div className="btn btn-icon btn-hover-transparent-white w-sm-auto d-flex align-items-center btn-lg px-2" sty id="kt_quick_user_toggle">
                        <div className="d-flex flex-column text-right pr-sm-3">
                          <span className="opacity-50 font-weight-bold font-size-sm d-none d-sm-inline" style={{color: 'black'}}>Hi</span>
                          <span className="font-weight-bolder font-size-sm d-none d-sm-inline" style={{color: 'black'}}></span>
                        </div>
                        <span className="symbol symbol-35" style={{backgroundColor: '#536DFE'}}>
                          <span className="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-30"><i className="far fa-bell" /></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="header-bottom">
                <div className="container">
                  <div className="header-navs header-navs-left" id="kt_header_navs">
                    <div className="tab-content">
                      <div className="tab-pane py-5 p-lg-0 show active" id="kt_header_tab_1">
                        <div id="kt_header_menu" className="header-menu header-menu-mobile header-menu-layout-default">
                          <ul className="menu-nav">
                            <li className="menu-item" >
                              <a href="dashboard" className="menu-link">
                                <span className="menu-text"> <i className="fas fa-chart-line" />&nbsp; Portfolio</span>
                              </a>
                            </li>
                            <li className="menu-item" >
                              <a href="activity" className="menu-link">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span className="menu-text"><i className="fas fa-bars" />&nbsp; Activity</span>
                                <span className="menu-desc" />
                                <i className="menu-arrow" />
                              </a>
                            </li>
                            <li className="menu-item menu-item-submenu menu-item-rel" data-menu-toggle="click" >
                              <a href="funding" className="menu-link menu-toggle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span className="menu-text" style={{color: '#3699FF'}}><i className="la la-plus-circle" />&nbsp;Funding</span>
                                <span className="menu-desc" />
                                <i className="menu-arrow" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
           
    <div className="content d-flex flex-column flex-column-fluid bg-gray" id="kt_content">
        <div className="subheader py-2 py-lg-6 subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <div className="d-flex align-items-center flex-wrap mr-1">
              <div className="d-flex align-items-baseline flex-wrap mr-5">
                <h5 className="text-dark font-weight-bold my-1 mr-5">Transfer Existing Account</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column-fluid">
          <div className="container">
            <div className="card card-custom">
              <div className="card-body p-0">
                <div className="wizard wizard-3" id="kt_wizard_v3" data-wizard-state="step-first" data-wizard-clickable="true">
                  <div className="wizard-nav">
                    <div className="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
                      <div className="wizard-step" data-wizard-type="step">
                        <a className="wizard-label" href="funding">
                          <h3 className="wizard-title">
                            Add or Move Funds</h3>
                          <div className="wizard-bar" />
                        </a>
                      </div>
                      <div className="wizard-step" data-wizard-type="step">
                        <div className="wizard-label">
                          <h3 className="wizard-title">
                            <span />Withdraw Funds</h3>
                          <div className="wizard-bar" />
                        </div>
                      </div>
                      <div className="wizard-step" data-wizard-type="step" data-wizard-state="current">
                        <div className="wizard-label">
                          <a href="transfer1">
                            <h3 className="wizard-title">
                              Transfer Account</h3></a>
                          <div className="wizard-bar" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center py-10 px-8 py-lg-12 px-lg-10">
                    <div className="col-xl-12 col-xxl-7">
                      <form className="form" style={{padding: '-40px', paddingLeft: '30px', paddingRight: '30px'}}>
                        <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                          <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left" style={{padding: '50px'}}>
                            <div className="d-flex flex-center">
                            </div>
                            <div className style={{textAlign: 'justify'}}>
                              <h3 className="font-weight-bold text-dark font-size-h2 text-center">Select your RRSP</h3>
                              <div className="form-group">
                                <div className="input-icon">
                                  <input type="text" className="form-control form-control-solid h-auto  border-0 rounded-lg font-size-h6" placeholder="Search for your Bank of FInancial Institution financial institution" />
                                </div>
                                <br />
                                <a href="transfer2">
                                  <img src="assets/media/screen.png" style={{width: '100%'}} title alt="" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>  
            </div>
        </div>
    </div>       
    );

};

export default Transfer1;
