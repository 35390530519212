import React,{ useState } from 'react';

import { useHistory } from 'react-router-dom';


function Funding(){
  const history = useHistory();
const myRealname = sessionStorage.getItem('firstname');


  const [isMActive, setMActive] = useState(true);
  const [isMBctive, setMBctive] = useState(true);

  const [checked , setState] = useState({
    regYes:'',
    bene: '',
    
  })

  const setDiv1 = (e) => {
    setMBctive(false)
    const regYes = false;
    sessionStorage.setItem('AccountTransfer', regYes);
  }

  const setNoDiv1 = (e) => {
    setMBctive(true)
    const regYes = false;
    sessionStorage.setItem('AccountTransfer', regYes);
    console.log(regYes);
  }

  const setDiv2 = (e) => {
    // alert('l')
  }


if (!sessionStorage.getItem('auth-token')) {
  console.log('no auth token set');
  history.push('/');
  //do something like redirect to login page
} else {
  const authToken = 'piggyalphalimited2209827462713182219@quiqw0okqp-pl';
  if (sessionStorage.getItem('auth-token') === authToken) {
      console.log('good token. Log in.')
      //do something like redirect to todo page
  } else {
      console.log('bad token.')
      history.push('/');
      //do something like redirect to login page
  }
}
    return (
     
      <div>
        <div className="d-flex flex-column flex-root">
          <div className="d-flex flex-row flex-column-fluid page">
            <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
              <div id="kt_header" className="header flex-column header-fixed" style={{backgroundColor: '#E4F9FF'}}>
                <div className="header-top">
                  <div className="container">
                    <div className="d-none d-lg-flex align-items-center mr-3">
                      <a href="dashboard" className="mr-20">
                        <img alt="Logo" src="assets/media/logos/piggy-1.png" className="max-h-35px" />
                      </a>
                    </div>
                    <div className="topbar" style={{backgroundColor: '#E4F9FF'}}>
                      <div className="topbar-item" style={{backgroundColor: '#E4F9FF'}}>
                        <div className="btn btn-icon btn-hover-transparent-white w-sm-auto d-flex align-items-center btn-lg px-2" sty id="kt_quick_user_toggle">
                          <div className="d-flex flex-column text-right pr-sm-3">
                            <span className="opacity-50 font-weight-bold font-size-sm d-none d-sm-inline" style={{color: 'black'}}>Hi {myRealname}</span>
                            <span className="font-weight-bolder font-size-sm d-none d-sm-inline" style={{color: 'black'}}> </span>
                          </div>
                          <span className="symbol symbol-35" style={{backgroundColor: '#536DFE'}}>
                            <span className="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-30"><i className="far fa-bell" /></span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="header-bottom">
                  <div className="container">
                    <div className="header-navs header-navs-left" id="kt_header_navs">
                      <div className="tab-content">
                        <div className="tab-pane py-5 p-lg-0 show active" id="kt_header_tab_1">
                          <div id="kt_header_menu" className="header-menu header-menu-mobile header-menu-layout-default">
                            <ul className="menu-nav">
                              <li className="menu-item" >
                                <a href="dashboard" className="menu-link">
                                  <span className="menu-text"> <i className="fas fa-chart-line" />&nbsp; Portfolio</span>
                                </a>
                              </li>
                              <li className="menu-item" >
                                <a href="activity" className="menu-link">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className="menu-text"><i className="fas fa-bars" />&nbsp; Activity</span>
                                  <span className="menu-desc" />
                                  <i className="menu-arrow" />
                                </a>
                              </li>
                              <li className="menu-item menu-item-submenu menu-item-rel" data-menu-toggle="click" >
                                <a href="funding" className="menu-link menu-toggle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className="menu-text" style={{color: '#3699FF'}}><i className="la la-plus-circle" />&nbsp;Funding</span>
                                  <span className="menu-desc" />
                                  <i className="menu-arrow" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <div className="content d-flex flex-column flex-column-fluid bg-gray" id="kt_content">
                <div className="subheader py-2 py-lg-6 subheader-transparent" id="kt_subheader">
                  <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-1">
                      <div className="d-flex align-items-baseline flex-wrap mr-5">
                        <h5 className="text-dark font-weight-bold my-1 mr-5">Funding</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column-fluid">
                  <div className="container">
                    <div className="card card-custom">
                      <div className="card-body p-0">
                        <div className="wizard wizard-3" id="kt_wizard_v3" data-wizard-state="step-first" data-wizard-clickable="true">
                          <div className="wizard-nav">
                            <div className="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
                              <div className="wizard-step" data-wizard-type="step" data-wizard-state="current">
                                <div className="wizard-label">
                                  <h3 className="wizard-title">
                                    <span />Add or Move Funds</h3>
                                  <div className="wizard-bar"></div>
                                </div>
                              </div>
                              <div className="wizard-step" data-wizard-type="step">
                                <div className="wizard-label">
                                  <h3 className="wizard-title">
                                    <span />Withdraw Funds</h3>
                                  <div className="wizard-bar"></div>
                                </div>
                              </div>
                              <div className="wizard-step" data-wizard-type="step">
                                <div className="wizard-label">
                                  <a href="transfer1" style={{color: '#b5b5c3'}}>
                                    <h3 className="wizard-title">
                                      Transfer Account</h3>
                                  </a>
                                  <div className="wizard-bar"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      <div className="row justify-content-center py-10 px-8 py-lg-12 px-lg-10">
                        <div className="col-xl-12 col-xxl-7">
                          <form className="form" style={{padding: '-40px', paddingLeft: '30px', paddingRight: '30px'}}>
                            <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                             <div className='row'>
                              <div className="form-group col-lg-6">
                                <label>Select Account Type</label>
                                <select type="text" className="form-control form-control-lg h-auto p-5 border-0 rounded-lg">
                                  <option value>-- select --</option>
                                  <option value>RSP</option>
                                  <option value>RESP</option>
                                  <option value>TFSA</option>
                                  <option value>Non-Registered</option>
                                </select>
                              </div>
                              <div className="form-group col-lg-6">
                                <label>Amount($)</label>
                                <input type="text" className="form-control form-control-solid h-auto p-5 border-0 rounded-lg" placeholder="Amount" />
                              </div>
                              </div>
                              <div className="form-group row">
                                    <label className="col-4 col-form-label">Would you like to add a monthly contribution?</label>
                                    <div class="radio-inline">
                                            <label class="radio radio-rounded">
                                                <input  value={checked.bene} onClick={setDiv1} type="radio" name="bene"/>
                                                <span></span>
                                                Yes
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input value={checked.bene} onClick={setNoDiv1} type="radio"  name="bene"/>
                                                <span></span>
                                                No
                                            </label>
                                        </div>
                                        </div>

                                    <div className = {isMBctive ? "invisible": "visible"}>
                                    <br />
                                    <div className="row">
                                        <div className="col-xl-6">
                                          <div className="form-group">
                                            <label>Schedule</label>
                                            <select type="text" className="form-control form-control-solid h-auto p-5 border-0 rounded-lg">
                                              <option value>--select schedule--</option>
                                              <option value>One time</option>
                                              <option value>Monthly</option>
                                              <option value>Annually</option>
                                            </select>
                                          </div>
                                        </div>
                                        <div className="col-xl-6">
                                          <div className="form-group">
                                            <label>Start Date</label>
                                            <div className="input-icon">
                                              <input type="date" className="form-control form-control-solid h-auto p-5 border-0 rounded-lg" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    <br/>
                                    
                                  </div>


                              <center><a type="button" className="btn btn-primary font-weight-bold text-uppercase px-9 py-4" href="BankInfo">Add Funds</a>
                              </center>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="exampleModalScrollable2" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left">
                  <div className="d-flex flex-center">
                  </div>
                  <div className>
                    <div className="row" style={{padding: '65px'}}>
                      <h3 className="font-weight-bold text-dark font-size-h3 text-center"><span><img src="assets/media/screen2.png" style={{width: '20%'}} alt=""/></span>Connect your account</h3>
                      <div className="form-group mb-5 col-12">
                        <label htmlFor="username">Card Number</label>
                        <input type="text" className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6" name placeholder="0000-0000-0000-0000"  />
                      </div>
                      <div className="form-group mb-5 col-12">
                        <label htmlFor="username">Password</label>
                        <input type="password" className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6" name placeholder="Enter CIBC banking password"  />
                      </div>
                    </div>
                    <center><button type="button" style={{float: 'right'}} data-toggle="modal" data-dismiss="modal" data-target="#exampleModalScrollable3" className="btn btn-lg btn-primary text-center font-weight-bold">Next</button></center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="exampleModalScrollable3" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left">
                  <div className="d-flex flex-center">
                  </div>
                  <div className style={{padding: '40px'}}>
                    <h3 className="font-weight-bold text-dark font-size-h3 text-center">Connect your account</h3>
                    <br />
                    <p className="text-dark">For your security, CIBC (Canadian Imperial Bank of Commerce) requires additional verification info.</p>
                    <p className="text-dark font-weight-bold">Please enter the verification code that was sent to your phone:<br /> 2XX-XXX-X613</p>
                    <div className="row">
                      <div className="form-group mb-5 col-12">
                        <label htmlFor="username">Enter verification Code</label>
                        <input type="text" className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6" name placeholder="0000-0000-0000-0000"  />
                      </div>
                      <span><small>&nbsp;&nbsp;&nbsp;Get new code</small></span>
                    </div>
                  </div>
                  <center><button type="button" style={{float: 'right'}} data-toggle="modal" data-dismiss="modal" data-target="#exampleModalScrollable4" className="btn btn-lg btn-primary text-center font-weight-bold">Connect</button></center>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="exampleModalScrollable4" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered " role="document">
            <div className="modal-content">
              <div className="modal-body" style={{height: 'auto', width: 'auto'}}>
                <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left">
                  <div className="d-flex flex-center">
                    <a href="0#">
                      <img src="assets/media/logos/favicon.ico.png" className="max-h-75px" alt="" />
                    </a>
                  </div>
                  <div className style={{textAlign: 'justify'}}>
                    <h3 className="font-weight-bold text-dark font-size-h2 text-center">Hello Sean</h3>
                    <center><p className="opacity-70 mr-4" style={{fontSize: '13px'}}>You are ready to start contributing to your future goals </p>
                    </center>
                  </div>
                  <center><a href="dashboard" className="btn btn-primary text-center font-weight-bold">Go to Portfolio</a></center>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="exampleModalScrollable" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left" style={{padding: '50px'}}>
                  <div className="d-flex flex-center">
                  </div>
                  <div className style={{textAlign: 'justify'}}>
                    <h3 className="font-weight-bold text-dark font-size-h2 text-center">Where is your Bank Account</h3>
                    <div className="form-group">
                      <div className="input-icon">
                        <input type="text" className="form-control form-control-solid h-auto  border-0 rounded-lg font-size-h6" placeholder="Search for your Bank of FInancial Institution financial institution" />
                      </div>
                      <br />
                      <a data-toggle="modal" href="0#" data-target="#exampleModalScrollable2" data-dismiss="modal">
                        <img src="assets/media/screen.png" style={{width: '100%'}} title alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>

         
    );
};

export default Funding;
