const dataFiles = [
    {
        batch_id: "010",
        rr_code: "202",
        registration_type: "Individual",
        client_prefix: "",
        client_marital_status: sessionStorage.getItem('marital'),
        client_first_name: sessionStorage.getItem('firstname'),
        client_middle_name: sessionStorage.getItem('middlename'),
        client_last_name: sessionStorage.getItem('lastname'),
        client_sin: sessionStorage.getItem('ssn'),
        client_dob: sessionStorage.getItem('birthday'),
        client_citizenship: sessionStorage.getItem('citizenship'),
        client_phone: sessionStorage.getItem('phone'),
        client_email: sessionStorage.getItem('email'),
        client_num_of_dependants: sessionStorage.getItem('NOD'),
        client_legal_address_street_name_rr: sessionStorage.getItem('address'),
        client_legal_address_city: sessionStorage.getItem('city'),
        client_legal_address_province_state: sessionStorage.getItem('province'),
        client_legal_address_province_state: sessionStorage.getItem('province'),
        client_legal_address_postal_zip_code: sessionStorage.getItem('postalCode'),
        client_employer_name : sessionStorage.getItem('empName'),
        client_type_of_business: sessionStorage.getItem('jobTitle'),
        client_occupation: sessionStorage.getItem('empStatus'),
        client_spouse_first_name: sessionStorage.getItem('spouseFirstname'),
        client_spouse_last_name: sessionStorage.getItem('spouseLastname'),
        client_spouse_employer_name: sessionStorage.getItem('spouseEmployer'),
        client_spouse_type_of_business: sessionStorage.getItem('spouseindustry'),
        client_liquid_assets: sessionStorage.getItem('amountSaved'),
        client_fixed_assets: sessionStorage.getItem('propValue')
    }
];

export default dataFiles 