import React from 'react';
import { useHistory } from 'react-router-dom';


function Dashboard1(){
  const history = useHistory();


if (!sessionStorage.getItem('auth-token')) {
  console.log('no auth token set');
  history.push('/');
  //do something like redirect to login page
} else {
  const authToken = 'piggyalphalimited2209827462713182219@quiqw0okqp-pl';
  if (sessionStorage.getItem('auth-token') === authToken) {
      console.log('good token. Log in.')
      //do something like redirect to todo page
  } else {
      console.log('bad token.')
      history.push('/');
      //do something like redirect to login page
  }
}
      return (
              <div>
                <div className="d-flex flex-column flex-root">
                  <div className="d-flex flex-row flex-column-fluid page">
                    <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                      <div id="kt_header" className="header flex-column header-fixed" style={{backgroundColor: '#E4F9FF'}}>
                        <div className="header-top">
                          <div className="container">
                            <div className="d-none d-lg-flex align-items-center mr-3">
                              <a href="0#" className="mr-20">
                                <img alt="Logo" src="assets/media/logos/piggy-1.png" className="max-h-35px" />
                              </a>
                            </div>
                            <div className="topbar" style={{backgroundColor: '#E4F9FF'}}>
                              <div className="topbar-item" style={{backgroundColor: '#E4F9FF'}}>
                                <div className="btn btn-icon btn-hover-transparent-white w-sm-auto d-flex align-items-center btn-lg px-2" sty id="kt_quick_user_toggle">
                                  <div className="d-flex flex-column text-right pr-sm-3">
                                    <span className="opacity-50 font-weight-bold font-size-sm d-none d-sm-inline" style={{color: 'black'}}>Hi</span>
                                    <span className="font-weight-bolder font-size-sm d-none d-sm-inline" style={{color: 'black'}}></span>
                                  </div>
                                  <span className="symbol symbol-35" style={{backgroundColor: '#536DFE'}}>
                                    <span className="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-30"><i className="far fa-bell" /></span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="header-bottom">
                          <div className="container">
                            <div className="header-navs header-navs-left" id="kt_header_navs">
                              <div className="tab-content">
                                <div className="tab-pane py-5 p-lg-0 show active" id="kt_header_tab_1">
                                  <div id="kt_header_menu" className="header-menu header-menu-mobile header-menu-layout-default">
                                    <ul className="menu-nav">
                                      <li className="menu-item" >
                                        <a href="0#" className="menu-link">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="menu-text" style={{color: '#3699FF'}}> <i className="fas fa-chart-line" />&nbsp; Portfolio</span>
                                        </a>
                                      </li>
                                      <li className="menu-item menu-item-submenu menu-item-rel" data-menu-toggle="click">
                                        <a href="0#" className="menu-link menu-toggle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="menu-text"><i className="fas fa-bars" />&nbsp; Activity</span>
                                          <span className="menu-desc" />
                                          <i className="menu-arrow" />
                                        </a>
                                      </li>
                                      <li className="menu-item menu-item-submenu menu-item-rel" data-menu-toggle="click">
                                        <a href="0#" className="menu-link menu-toggle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="menu-text"><i className="la la-plus-circle" />&nbsp;Funding</span>
                                          <span className="menu-desc" />
                                          <i className="menu-arrow" />
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <div className="content d-flex flex-column flex-column-fluid bg-gray" id="kt_content">
                        <div className="subheader py-2 py-lg-6 subheader-transparent" id="kt_subheader">
                          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                            <div className="d-flex align-items-center flex-wrap mr-1">
                            </div>
                          </div>
                        </div>
                        <div className="row justify-content-center" style={{paddingLeft: '100px', paddingRight: '100px'}}>
                          <div className="col-lg-5">
                            <div className="card card-custom gutter-b">
                              <div className="card-body" style={{borderRadius: '50px'}}>
                                <h4 className="text-center">Hello , you have</h4>
                                <br />
                                <h1 className="text-center font-weight-bolder text-muted" style={{fontSize: '29px'}}>$0.00
                                </h1>
                                <p />
                              </div>
                            </div>
                            <div className="card card-custom gutter-b">
                              <div className="card-body" style={{borderRadius: '50px'}}>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <div className="d-flex justify-content-center">
                                  <p className="text-center text-muted">No Data Available</p>
                                </div>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <div className="card card-custom gutter-b">
                              <div className="card-header">
                                <div className="card-title">
                                  <h3 className="card-label">Financials</h3>
                                </div>
                              </div>
                              <div className="card-body">
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                              <div className="d-flex justify-content-center">
                                  <p className="text-center text-muted">No Data Available</p>
                                </div>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>

                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row justify-content-center" style={{paddingLeft: '100px', paddingRight: '100px'}}>
                          <div className="col-lg-5">
                            <div className="card card-custom gutter-b">
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              <div className="card-body" style={{borderRadius: '50px'}}>
                                <p className="text-center text-muted">No Data Available</p>
                                <br />
                              </div>
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <div className="card card-custom gutter-b">
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              <div className="card-body">
                                <p className="text-center text-muted">You have not added any accounts</p>
                              </div>
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal fade" id="exampleModalScrollable1" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                      <div className="modal-body" style={{height: 'auto', width: 'auto'}}>
                        <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left">
                          <div className="d-flex flex-center">
                            <a href="0#">
                              <img src="assets/media/favicon.png" className="max-h-55px" alt="" />
                            </a>
                          </div>
                          <div className style={{padding: '35px', textAlign: 'center'}}>
                            <h3 className="font-weight-bold text-dark font-size-h2 text-center"> Your account is incomplete.</h3>
                            <br />
                            <p className="opacity-70 text-center" style={{marginBottom: '-2px', fontSize: '13px', paddingLeft: '80px', paddingRight: '80px'}}>We need to collect some information from you so that we can verify your identity, setup your account and ensure your account is secure.</p>
                            <br />
                            <p className="opacity-70 text-center" style={{marginBottom: '-2px', fontSize: '13px', paddingLeft: '80px', paddingRight: '80px'}}>Click on Register below and let us build the right plan for you.</p>
                            <br />
                            <center><a type="button" href="wizard" className="btn btn-primary text-center font-weight-bold">Register</a></center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        );
  };

export default Dashboard1;
