import React from 'react';
import { useHistory } from 'react-router-dom';


function Activity(){
  const history = useHistory();
  const myRealname = sessionStorage.getItem('firstname');



if (!sessionStorage.getItem('auth-token')) {
  console.log('no auth token set');
  history.push('/');
  //do something like redirect to login page
} else {
  const authToken = 'piggyalphalimited2209827462713182219@quiqw0okqp-pl';
  if (sessionStorage.getItem('auth-token') === authToken) {
      console.log('good token. Log in.')
      //do something like redirect to todo page
  } else {
      console.log('bad token.')
      history.push('/');
      //do something like redirect to login page
  }
}
    return (
                <div>
                  <div className="d-flex flex-column flex-root">
                    <div className="d-flex flex-row flex-column-fluid page">
                      <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                        <div id="kt_header" className="header flex-column header-fixed" style={{backgroundColor: '#E4F9FF'}}>
                          <div className="header-top">
                            <div className="container">
                              <div className="d-none d-lg-flex align-items-center mr-3">
                                <a href="0#" className="mr-20">
                                  <img alt="Logo" src="assets/media/logos/piggy-1.png" className="max-h-35px" />
                                </a>
                              </div>
                              <div className="topbar" style={{backgroundColor: '#E4F9FF'}}>
                                <div className="topbar-item" style={{backgroundColor: '#E4F9FF'}}>
                                  <div className="btn btn-icon btn-hover-transparent-white w-sm-auto d-flex align-items-center btn-lg px-2" sty id="kt_quick_user_toggle">
                                    <div className="d-flex flex-column text-right pr-sm-3">
                                      <span className="opacity-50 font-weight-bold font-size-sm d-none d-sm-inline" style={{color: 'black'}}>Hi {myRealname}</span>
                                    </div>
                                    <span className="symbol symbol-35" style={{backgroundColor: '#536DFE'}}>
                                      <span className="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-30"><i className="far fa-bell" /></span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="header-bottom">
                            <div className="container">
                              <div className="header-navs header-navs-left" id="kt_header_navs">
                                <div className="tab-content">
                                  <div className="tab-pane py-5 p-lg-0 show active" id="kt_header_tab_1">
                                    <div id="kt_header_menu" className="header-menu header-menu-mobile header-menu-layout-default">
                                      <ul className="menu-nav">
                                        <li className="menu-item" aria-haspopup="true">
                                          <a href="dashboard" className="menu-link">
                                            <span className="menu-text"> <i className="fas fa-chart-line" />&nbsp; Portfolio</span>
                                          </a>
                                        </li>
                                        <li className="menu-item" aria-haspopup="true">
                                          <a href="activity" className="menu-link">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="menu-text" style={{color: '#3699FF'}}><i className="fas fa-bars" />&nbsp; Activity</span>
                                            <span className="menu-desc" />
                                            <i className="menu-arrow" />
                                          </a>
                                        </li>
                                        <li className="menu-item" aria-haspopup="true">
                                          <a href="funding" className="menu-link">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="menu-text"><i className="la la-plus-circle" />&nbsp;Funding</span>
                                            <span className="menu-desc" />
                                            <i className="menu-arrow" />
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                          <div className="subheader py-2 py-lg-6 subheader-transparent" id="kt_subheader">
                            <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                              <div className="d-flex align-items-center flex-wrap mr-1">
                                <div className="d-flex align-items-baseline flex-wrap mr-5">
                                  <h5 className="text-dark font-weight-bold my-1 mr-5">Activity</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex flex-column-fluid">
                            <div className="container">
                              <div className="card card-custom">
                                <div className="card-body p-0">
                                  <div className="wizard wizard-3" id="kt_wizard_v3" data-wizard-state="step-first" data-wizard-clickable="true">
                                    <div className="row justify-content-center py-10 py-lg-12 px-lg-10">
                                      <div className="col-xl-12 col-xxl-7">
                                        <form className="form" style={{padding: '-40px', paddingLeft: '50px', paddingRight: '50px'}}>
                                          <center>
                                            No data Available
                                            <span><i className="fa fa-plus fa-1x" style={{float: 'right'}} /></span>
                                          </center>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="kt_quick_user" className="offcanvas offcanvas-right p-10">
                    <div className="offcanvas-content pr-5 mr-n5">
                      <div className="d-flex align-items-center mt-5">
                        <div className="symbol symbol-70 mr-5">
                          <div className="symbol-label" style={{backgroundImage: 'url("assets2/media/users/300_21.jpg")'}} />
                          <i className="symbol-badge bg-success" />
                        </div>
                        <div className="d-flex flex-column">
                          <div className="text-muted mt-1">{myRealname}</div>
                          <div className="navi mt-2">
                            <a href="0#" className="btn btn-sm btn-light-primary font-weight-bolder py-2 px-5">Sign Out</a>
                          </div>
                        </div>
                      </div>
                      <div className="separator separator-dashed mt-8 mb-5" />
                      <div className="navi navi-spacer-x-0 p-0">
                        <a href="0#" className="navi-item">
                          <div className="navi-link">
                            <div className="navi-text">
                              <div className="font-weight-bold">My Documents</div>
                              <div className="text-muted">Account settings and more</div>
                            </div>
                          </div>
                        </a>
                        <a href="0#" className="navi-item">
                          <div className="navi-link">
                            <div className="navi-text">
                              <div className="font-weight-bold">Settings</div>
                              <div className="text-muted">Setup and manage your PiggyAlphaaccount</div>
                            </div>
                          </div>
                        </a>
                        <a href="0#" className="navi-item">
                          <div className="navi-link">
                            <div className="navi-text">
                              <div className="font-weight-bold">Help</div>
                              <div className="text-muted"> Contact our customer support for help </div>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="separator separator-dashed my-7" />
                      <div>
                        <h5 className="mb-5">Recent Notifications</h5>
                        <div className="d-flex align-items-center bg-light-warning rounded p-5 gutter-b">
                          <div className="d-flex flex-column flex-grow-1 mr-2">
                            <a href="0#" className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1">Something goes here</a>
                            <span className="text-muted font-size-sm">Trade xxx on July 10</span>
                          </div>
                          <span className="font-weight-bolder text-warning py-1 font-size-lg">+10%</span>
                        </div>
                        <div className="d-flex align-items-center bg-light-success rounded p-5 gutter-b">
                          <div className="d-flex flex-column flex-grow-1 mr-2">
                            <a href="0#" className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1">Something goes here</a>
                            <span className="text-muted font-size-sm">Trade xxx on July 10</span>
                          </div>
                          <span className="font-weight-bolder text-success py-1 font-size-lg">+10%</span>
                        </div>
                        <div className="d-flex align-items-center bg-light-danger rounded p-5 gutter-b">
                          <div className="d-flex flex-column flex-grow-1 mr-2">
                            <a href="0#" className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1">Something goes here</a>
                            <span className="text-muted font-size-sm">Trade xxx on July 10</span>
                          </div>
                          <span className="font-weight-bolder text-danger py-1 font-size-lg">+10%</span>
                        </div>
                        <div className="d-flex align-items-center bg-light-info rounded p-5">
                          <div className="d-flex flex-column flex-grow-1 mr-2">
                            <a href="0#" className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1">Something goes here</a>
                            <span className="text-muted font-size-sm">Trade xxx on July 10</span>
                          </div>
                          <span className="font-weight-bolder text-info py-1 font-size-lg">+8%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    );
};

export default Activity;
