import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import SignIn1 from './component/signin1/signin1';
import signin from './component/signin/signin';
import signup from './component/signup/signup';
import activity from './component/activity/activity';
import transfer1 from './component/transfer/transfer1';
import transfer2 from './component/transfer/transfer2';
import dashboard from './component/dashboard/dashboard';
import dashboard1 from './component/dashboard/dashboard1';
import funding from './component/funding/funding';
import wizard from './component/wizard/wizard';
import PersonalInfo from './component/wizard/personal_info';
import FamilyInfo from './component/wizard/family_info';
import FamilyInfoo from './component/wizard/family_infoo';
import InvestInfo from './component/wizard/InvestInfo';
import FinancialInfo from './component/wizard/FinancialInfo';
import Portfolio from './component/wizard/Portfolio';
import Employment from './component/wizard/employment';
import Antimoney from './component/wizard/antimoney';
import Setup from './component/wizard/setup';
import Preview from './component/wizard/preview';
import Reason from './component/wizard/reason';
import Regulatory from './component/wizard/Regulatory';
import BankInfo from './component/funding/bankInfo';



export default function BasicExample() {
  return (
    <Router>
      <div>
       

        <Switch>
          <Route exact path="/" component={SignIn1}/>
          <Route path="/signin" component={signin}/>
          <Route path="/signup" component={signup}/>
          <Route path="/funding" component={funding}/>
          <Route path="/bankInfo" component={BankInfo}/>
          <Route path="/activity" component={activity}/>
          <Route path="/transfer1" component={transfer1}/>
          <Route path="/transfer2" component={transfer2}/>
          <Route path="/wizard" component={wizard}/>
          <Route path="/Regulatory" component={Regulatory}/>
          <Route path="/PersonalInfo" component={PersonalInfo}/>
          <Route path="/dashboard" component={dashboard}/>
          <Route path="/dashboard1" component={dashboard1}/>
          <Route path="/FamilyInfo" component={FamilyInfo}/>
          <Route path="/FamilyInfoo" component={FamilyInfoo}/>
          <Route path="/InvestInfo" component={InvestInfo}/>
          <Route path="/FinancialInfo" component={FinancialInfo}/>
          <Route path="/Portfolio" component={Portfolio}/>
          <Route path="/Employment" component={Employment}/>
          <Route path="/Antimoney" component={Antimoney}/>
          <Route path="/Setup" component={Setup}/>
          <Route path="/Reason" component={Reason}/>
          <Route path="/Preview" component={Preview}/>
          
            
          
        </Switch>
      </div>
    </Router>
  );
}

// You can think of these components as "pages"
// in your app.

function Home() {
  return (
    <div>
      <h2>Home</h2>
    </div>
  );
}

function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}

function Dashboard() {
  return (
    <div className="d-flex flex-column flex-root">
    <div className="login login-4 login-signin-on d-flex flex-row-fluid" id="kt_login">
      <div className="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat" style={{backgroundColor: 'white'}}>
        <div className="login-form p-7 position-relative overflow-hidden">
          <div className="d-flex flex-center">
            <a>
              <img src="assets/media/logos/logo.png" className="max-h-75px" alt="" />
            </a>
          </div>
          <div className="card card-board" style={{backgroundColor: '#F0F1F4', width: '100%'}}>
            <div className="card-body">
              <div className="mb-10">
                <h3 className="font-weight-bold">Welcome to PiggyAlpha</h3>
                <div className>We are here to help you achieve your investment objective in the easiest way possible</div>
              </div>
              <form className="form" id="kt_login_signin_form">
                <div className="form-group mb-5">
                  <label htmlFor="username">Email</label>
                  <input className="form-control h-auto form-control-solid py-4 px-8" style={{backgroundColor: 'white'}} type="text" placeholder="Email" name="username" autoComplete="off" />
                </div>
                <div className="form-group mb-5">
                  <label htmlFor="username">Password</label>
                  <a href="" id="kt_login_forgot" style={{float: 'right', color: 'blue'}} className>Forgot Password ?</a>
                  <input className="form-control h-auto form-control-solid py-4 px-8" style={{backgroundColor: 'white'}} type="password" placeholder="Password" name="password" />
                </div>
                <button id="kt_login_signin_submit" className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4">Sign In</button>
              </form>
            </div>
          </div>
          <div className="mt-10 text-center">
            <span className="opacity-70 mr-4">New Here? </span>
            <a href="" id="kt_login_signup" className="text-muted text-hover-primary font-weight-bold">Create Account?</a>
          </div>
          <div className="login-signup">
            <div className="mb-20">
              <h3>Sign Up</h3>
              <div className="text-muted font-weight-bold">Enter your details to create your account</div>
            </div>
            <form className="form" id="kt_login_signup_form">
              <div className="form-group mb-5">
                <input className="form-control h-auto form-control-solid py-4 px-8" type="text" placeholder="Fullname" name="fullname" />
              </div>
              <div className="form-group mb-5">
                <input className="form-control h-auto form-control-solid py-4 px-8" type="text" placeholder="Email" name="email" autoComplete="off" />
              </div>
              <div className="form-group mb-5">
                <input className="form-control h-auto form-control-solid py-4 px-8" type="password" placeholder="Password" name="password" />
              </div>
              <div className="form-group mb-5">
                <input className="form-control h-auto form-control-solid py-4 px-8" type="password" placeholder="Confirm Password" name="cpassword" />
              </div>
              <div className="form-group mb-5 text-left">
                <div className="checkbox-inline">
                  <label className="checkbox m-0">
                    <input type="checkbox" name="agree" />
                    <span />I Agree the
                    <a href="#" className="font-weight-bold ml-1">terms and conditions</a>.</label>
                </div>
                <div className="form-text text-muted text-center" />
              </div>
              <div className="form-group d-flex flex-wrap flex-center mt-10">
                <button id="kt_login_signup_submit" className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-2">Sign Up</button>
                <button id="kt_login_signup_cancel" className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-2">Cancel</button>
              </div>
            </form>
          </div>
          <div className="login-forgot">
            <div className="mb-20">
              <h3>Forgotten Password ?</h3>
              <div className="text-muted font-weight-bold">Enter your email to reset your password</div>
            </div>
            <form className="form" id="kt_login_forgot_form">
              <div className="form-group mb-10">
                <input className="form-control form-control-solid h-auto py-4 px-8" type="text" placeholder="Email" name="email" autoComplete="off" />
              </div>
              <div className="form-group d-flex flex-wrap flex-center mt-10">
                <button id="kt_login_forgot_submit" className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-2">Request</button>
                <button id="kt_login_forgot_cancel" className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-2">Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}
