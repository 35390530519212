import React from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Modal, Button,Alert, dismissible } from 'react-bootstrap';



function SignIn(){
  const history = useHistory();

  const checkSignIn = (e) => {

  }

  


if (!sessionStorage.getItem('auth-token')) {
  console.log('no auth token set');
  history.push('/');
  //do something like redirect to login page
} else {
  const authToken = 'piggyalphalimited2209827462713182219@quiqw0okqp-pl';
  if (sessionStorage.getItem('auth-token') === authToken) {
      console.log('good token. Log in.')
      //do something like redirect to todo page
  } else {
      console.log('bad token.')
      history.push('/');
      //do something like redirect to login page
  }
}
      return (
  
        <div className="d-flex flex-column flex-root">
          <div className="login login-4 login-signin-on d-flex flex-row-fluid" id="kt_login">
            <div className="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat" style={{backgroundColor: 'white'}}>
              <div className="login-form p-7 position-relative overflow-hidden">
                <div className="d-flex flex-center">
                  <div>
                    <img src="assets/media/logos/logo.png" className="max-h-75px" alt="" />
                  </div>
                </div>
                <div className="card card-board" style={{backgroundColor: '#F0F1F4', width: '100%'}}>
                  <div className="card-body">
                    <div className="mb-10">
                      <h3 className="font-weight-bold">Welcome to PiggyAlpha</h3>
                      <div className>We are here to help you achieve your investment objective in the easiest way possible</div>
                    </div>
                    <form className="form" >
                      <div className="form-group mb-5">
                        <label htmlFor="username">Email Address</label>
                        <input className="form-control h-auto form-control-solid py-4 px-8" style={{backgroundColor: 'white'}} type="text" placeholder="Email" name="username" autoComplete="off" />
                      </div>
                      <div className="form-group mb-5">
                        <label htmlFor="username">Password</label>
                        <Link to={""}  style={{float: 'right', color: '#536DFE'}} className>Forgot Password ?</Link>
                        <input className="form-control h-auto form-control-solid py-4 px-8" style={{backgroundColor: 'white'}} type="password" placeholder="Password" name="password" />
                      </div>
                      <button onClick={checkSignIn} className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4">Sign In</button>
                    </form>
                  </div>
                </div>
                <div className="mt-10 text-center">
                  <span className="opacity-70 mr-4">New Here? </span>
                  <Link to={"signup"}  className=" text-hover-primary font-weight-bold" style={{color: '#536DFE'}}>Create an Account?</Link>
                </div>
                <br></br>
                <div className="mt-10 text-center">
                  <span className="opacity-70 mr-4" style={{color: '#536DFE',fontWeight: 'bold'}}><a href="https://alphawealth.io/terms">Terms</a> </span>
                  <span className="opacity-70 mr-4" style={{color: '#536DFE', fontWeight: 'bold'}}><a href="https://alphawealth.io/#contact">Contact us </a></span>
                  
                  
                </div>
              </div>
            </div>
          </div>
          (<div>
      
    </div>)
        </div>
      );
};
  

export default SignIn;
