import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';

  function SignIn1(){
  const [passwordInput, setPasswordInput] = useState('');

  const history = useHistory();
 
  const handlePasswordChange = (e) => {
    setPasswordInput(e.target.value);
  }
  

  const handleLoginSubmit = (e) => {
    console.log(passwordInput);
    let hardcodedCred = {
        password: 'password1234'
    }

    if ((passwordInput === hardcodedCred.password)) {
        const token = 'piggyalphalimited2209827462713182219@quiqw0okqp-pl';
        sessionStorage.setItem('auth-token', token);
        //go to www.website.com/todo
        history.push('/signin');
    } else {
        //bad combination
        // alert('wrong email or password combination');
        e.target.reset();
        alert('wrong password, please try again');
    }
  }
      return ( 
        <div className="d-flex flex-column flex-root">
          <div className="login login-4 login-signin-on d-flex flex-row-fluid" id="kt_login">
            <div className="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat" style={{backgroundColor: 'white'}}>
              <div className="login-form p-7 position-relative overflow-hidden">
                <div className="d-flex flex-center">
                <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
                </div>
                <div className="card card-board"  style={{backgroundColor: '#F0F1F4', width: '100%'}}>
                  <div className="card-body">
                    <div className="mb-10">
                      <h3 className="font-weight-bold">This website is password protected</h3>
                    </div> 
                    <form className="form" autoComplete="off" onSubmit={(e)=>handleLoginSubmit(e)}>
                      <div className="form-group mb-5">
                        <label htmlFor="username">Enter Unique Password</label>
                        <input  className="form-control h-auto form-control-solid py-4 px-8"
                                 style={{backgroundColor: 'white'}} 
                                 type="password" 
                                 placeholder="Password" 
                                 name="password"
                                 value={passwordInput}
                                 onChange={handlePasswordChange} />
                      </div>
                      <input type="submit" className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4" value="Enter" />
                    </form>
                  </div>
                </div>


               
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              </div>
            </div>
          </div>
        </div>
      );

  };

export default SignIn1;
