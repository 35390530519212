import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import NumberFormat from "react-number-format";
import { Modal, Button,Alert} from 'react-bootstrap';



function InvestInfo(){

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const [state , setState] = useState({
  amountSaved: sessionStorage.getItem('amountSaved'), 
  propValue: sessionStorage.getItem('propValue'), 
  debtValue: sessionStorage.getItem('debtValue'), 
})

const handleWizardChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
}

const handleWizardClick = (e) => {
  if(!state.debtValue){
    setShow(true)
  }else if(!state.amountSaved){
    setShow(true)
  }else if(!state.propValue){
    setShow(true)
  }else if (state.propValue && state.amountSaved && state.debtValue) {
    sessionStorage.setItem('amountSaved', state.amountSaved);
    sessionStorage.setItem('propValue', state.propValue);
    sessionStorage.setItem('debtValue', state.debtValue);
    window.location.href="Portfolio";

  } 

}

  const history = useHistory();


if (!sessionStorage.getItem('auth-token')) {
  console.log('no auth token set');
  history.push('/');
  //do something like redirect to login page
} else {
  const authToken = 'piggyalphalimited2209827462713182219@quiqw0okqp-pl';
  if (sessionStorage.getItem('auth-token') === authToken) {
      console.log('good token. Log in.')
      //do something like redirect to todo page
  } else {
      console.log('bad token.')
      history.push('/');
      //do something like redirect to login page
  }
  
}
    return (
      
            <div className="d-flex flex-column flex-root">
              <div className="d-flex flex-row flex-column-fluid page">
                <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                  <div id="kt_header" className="header flex-column header-fixed" style={{backgroundColor: '#E4F9FF'}}>
                    <div className="header-top">
                      <div className="container">
                        <div className="d-none d-lg-flex align-items-center mr-3">
                          <a href="dashboard" className="mr-20">
                            <img alt="Logo" src="assets/media/logos/piggy-1.png" className="max-h-35px" />
                          </a>
                        </div>
                        <div className="topbar" style={{backgroundColor: '#E4F9FF'}}>
                          <div className="topbar-item" style={{backgroundColor: '#E4F9FF'}}>
                            <div className="btn btn-icon btn-hover-transparent-white w-sm-auto d-flex align-items-center btn-lg px-2" sty id="kt_quick_user_toggle">
                              <div className="d-flex flex-column text-right pr`-sm-3">
                                <span className="opacity-50 font-weight-bold font-size-sm d-none d-sm-inline" style={{color: 'black'}}>Hi</span>
                                <span className="font-weight-bolder font-size-sm d-none d-sm-inline" style={{color: 'black'}}></span>
                              </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <span className="symbol symbol-35" style={{backgroundColor: '#536DFE'}}>
                                <span className="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-30"><i className="far fa-bell" /></span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="header-bottom">
                      <div className="container">
                        <div className="header-navs header-navs-left" id="kt_header_navs">
                          <div className="tab-content">
                            <div className="tab-pane py-5 p-lg-0 show active" id="kt_header_tab_1">
                              <div id="kt_header_menu" className="header-menu header-menu-mobile header-menu-layout-default">
                                <ul className="menu-nav">
                                  <li className="menu-item" >
                                    <a href className="menu-link">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <span className="menu-text"> <i className="fas fa-chart-line" />&nbsp; Portfolio</span>
                                    </a>
                                  </li>
                                  <li className="menu-item menu-item-submenu menu-item-rel" data-menu-toggle="click" >
                                    <a href="0#" className="menu-link menu-toggle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <span className="menu-text"><i className="fas fa-bars" />&nbsp; Activity</span>
                                      <span className="menu-desc" />
                                      <i className="menu-arrow" />
                                    </a>
                                  </li>
                                  <li className="menu-item menu-item-submenu menu-item-rel" data-menu-toggle="click" >
                                    <a href="0#" className="menu-link menu-toggle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <span className="menu-text"><i className="la la-plus-circle" />&nbsp;Funding</span>
                                      <span className="menu-desc" />
                                      <i className="menu-arrow" />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <div className="content d-flex flex-column flex-column-fluid bg-gray" id="kt_content">
                    <div className="subheader py-2 py-lg-6 subheader-transparent" id="kt_subheader">
                      <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                        <div className="d-flex align-items-center flex-wrap mr-1">
                          <div className="d-flex align-items-baseline flex-wrap mr-5">
                            <h5 className="text-dark font-weight-bold my-1 mr-5">Setup your profile</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column-fluid">
                      <div className="container">
                        <div className="card card-custom">
                          <div className="card-body p-0">
                            <div className="wizard wizard-6 d-flex flex-column flex-lg-row flex-column-fluid" id="kt_wizarded">
                              <div className="wizard-content d-flex flex-column mx-auto py-10 py-lg-20 w-100 w-md-700px">
                                <div className="d-flex flex-column-auto flex-column ">
                                  <div className="wizard-nav pb-lg-10 pb-3 d-flex flex-column align-items-center align-items-md-start">
                                    <div className="wizard-steps d-flex flex-column flex-md-row">
                                      <div className="wizard-step flex-grow-1 flex-basis-0" data-wizard-type="step" data-wizard-state="current">
                                        <div className="wizard-wrapper pr-lg-7 pr-5">
                                          <div className="wizard-icon">
                                            <i className="wizard-check ki ki-check" />
                                            <span className="wizard-number">1</span>
                                          </div>
                                          <div className="wizard-label mr-3">
                                            <div className="wizard-desc">Personal details</div>
                                          </div>
                                          <span className="svg-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24" />
                                                <rect fill="#000000" opacity="0.3" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width={2} height={9} rx={1} />
                                                <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                              </g>
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="wizard-step flex-grow-1 flex-basis-0" data-wizard-type="step">
                                        <div className="wizard-wrapper pr-lg-7 pr-5">
                                          <div className="wizard-icon">
                                            <i className="wizard-check ki ki-check" />
                                            <span className="wizard-number">2</span>
                                          </div>
                                          <div className="wizard-label mr-3">
                                            <div className="wizard-desc">Employment information</div>
                                          </div>
                                          <span className="svg-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24" />
                                                <rect fill="#000000" opacity="0.3" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width={2} height={9} rx={1} />
                                                <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                              </g>
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="wizard-step flex-grow-1 flex-basis-0" data-wizard-type="step">
                                        <div className="wizard-wrapper pr-lg-7 pr-5">
                                          <div className="wizard-icon">
                                            <i className="wizard-check ki ki-check" />
                                            <span className="wizard-number">3</span>
                                          </div>
                                          <div className="wizard-label mr-3">
                                            <div className="wizard-desc">Regulatory Questions</div>
                                          </div>
                                          <span className="svg-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24" />
                                                <rect fill="#000000" opacity="0.3" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width={2} height={9} rx={1} />
                                                <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                              </g>
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="wizard-step flex-grow-1 flex-basis-0" data-wizard-type="step">
                                        <div className="wizard-wrapper pr-lg-7 pr-5">
                                          <div className="wizard-icon">
                                            <i className="wizard-check ki ki-check" />
                                            <span className="wizard-number">4</span>
                                          </div>
                                          <div className="wizard-label mr-3">
                                            <div className="wizard-desc">Client Investor Profile</div>
                                          </div>
                                          <span className="svg-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24" />
                                                <rect fill="#000000" opacity="0.3" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width={2} height={9} rx={1} />
                                                <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                              </g>
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="wizard-step flex-grow-1 flex-basis-0" data-wizard-type="step">
                                        <div className="wizard-wrapper pr-lg-7 pr-5">
                                          <div className="wizard-icon">
                                            <i className="wizard-check ki ki-check" />
                                            <span className="wizard-number">5</span>
                                          </div>
                                          <div className="wizard-label mr-3">
                                            <div className="wizard-desc">Setup Account</div>
                                          </div>
                                          <span className="svg-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24" />
                                                <rect fill="#000000" opacity="0.3" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width={2} height={9} rx={1} />
                                                <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                              </g>
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="wizard-step flex-grow-1 flex-basis-0" data-wizard-type="step">
                                        <div className="wizard-wrapper pr-lg-7 pr-5">
                                          <div className="wizard-icon">
                                            <i className="wizard-check ki ki-check" />
                                            <span className="wizard-number">6</span>
                                          </div>
                                          <div className="wizard-label mr-3">
                                            <div className="wizard-desc">Preview and Submit</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <form className="px-10" noValidate="novalidate" id="kt_form">
                                  <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                    <div className="pb-10 pb-lg-12 text-center text-md-left">
                                      <h3 className="font-weight-bolder text-dark font-size-h2">Family Details</h3>
                                    </div>
                                    <div className="row">
                                    <div className="form-group mb-5 col-6">
                                        <label htmlFor="username">Marital Status</label>
                                        <select className="form-control form-control-lg h-auto  rounded-lg">
                                        <option value="Single">-- select marital status--</option>
                                          <option value="Single">Single</option>
                                          <option value="Married">Married</option>
                                          <option value="Divorced">Divorced</option>
                                          </select>
                                          </div>

                                          <div className="form-group mb-5 col-6">
                                        <label htmlFor="username" >Spouse Firstname</label>
                                        <input type="text" className="form-control form-control-lg h-auto rounded-lg" placeholder="What is your spouse firstname"/>
                                      </div>
                                      
                                     
                                    </div>
                                    <div className="row">
                                    <div className="form-group mb-5 col-6">
                                        <label htmlFor="username" >Spouse Lastname</label>
                                        <input type="text" className="form-control form-control-lg h-auto rounded-lg" placeholder="What is your spouse lastname"/>
                                      </div>
                                      <div className="form-group mb-5 col-6">
                                        <label htmlFor="username" >Spouse Birthday</label>
                                        <input type="date" className="form-control form-control-lg h-auto rounded-lg" placeholder="What is your spouse firstname"/>
                                      </div>
                                    </div>
                                    <div className="row">
                                    <div className="form-group mb-5 col-6">
                                        <label htmlFor="username" >Spouse Employer</label>
                                        <input type="text" className="form-control form-control-lg h-auto rounded-lg" placeholder="What is your spouse work"/>
                                      </div>

                                      <div className="col-xl-6">
                                        <div className="form-group">
                                          <label>Industry of Occupation</label>
                                          <select className="form-control form-control-lg h-auto  rounded-lg">
                                         
                                            <optgroup label="Healthcare Practitioners and Technical Occupations:">
                                              <option value="">  --</option>
                                              <option value="1">-  Chiropractor</option>
                                              <option value="2">-  Dentist</option>
                                              <option value="3">-  Dietitian or Nutritionist</option>
                                              <option value="4">-  Optometrist</option>
                                              <option value="5">-  Pharmacist</option>
                                              <option value="6">-  Physician</option>
                                              <option value="7">-  Physician Assistant</option>
                                              <option value="8">-  Podiatrist</option>
                                              <option value="9">-  Registered Nurse</option>
                                              <option value="10">-  Therapist</option>
                                              <option value="11">-  Veterinarian</option>
                                              <option value="12">-  Health Technologist or Technician</option>
                                              <option value="13">-  Other Healthcare Practitioners and Technical Occupation</option>
                                            </optgroup>
                                            <optgroup label="Healthcare Support Occupations:">
                                              <option value="14">-  Nursing, Psychiatric, or Home Health Aide</option>
                                              <option value="15">-  Occupational and Physical Therapist Assistant or Aide</option>
                                              <option value="16">-  Other Healthcare Support Occupation</option>
                                            </optgroup>
                                            <optgroup label="Business, Executive, Management, and Financial Occupations:">
                                              <option value="17">-  Chief Executive</option>
                                              <option value="18">-  General and Operations Manager</option>
                                              <option value="19">-  Advertising, Marketing, Promotions, Public Relations, and Sales Manager</option>
                                              <option value="20">-  Operations Specialties Manager (e.g., IT or HR Manager)</option>
                                              <option value="21">-  Construction Manager</option>
                                              <option value="22">-  Engineering Manager</option>
                                              <option value="23">-  Accountant, Auditor</option>
                                              <option value="24">-  Business Operations or Financial Specialist</option>
                                              <option value="25">-  Business Owner</option>
                                              <option value="26">-  Other Business, Executive, Management, Financial Occupation</option>
                                            </optgroup>
                                            <optgroup label="Architecture and Engineering Occupations:">
                                              <option value="27">-  Architect, Surveyor, or Cartographer</option>
                                              <option value="28">-  Engineer</option>
                                              <option value="29">-  Other Architecture and Engineering Occupation</option>
                                            </optgroup>
                                            <optgroup label="Education, Training, and Library Occupations:">
                                              <option value="30">-  Postsecondary Teacher (e.g., College Professor)</option>
                                              <option value="31">-  Primary, Secondary, or Special Education School Teacher</option>
                                              <option value="32">-  Other Teacher or Instructor</option>
                                              <option value="33">-  Other Education, Training, and Library Occupation</option>
                                            </optgroup>
                                            <optgroup label="Other Professional Occupations:">
                                              <option value="34">-  Arts, Design, Entertainment, Sports, and Media Occupations</option>
                                              <option value="35">-  Computer Specialist, Mathematical Science</option>
                                              <option value="36">-  Counselor, Social Worker, or Other Community and Social Service Specialist</option>
                                              <option value="37">-  Lawyer, Judge</option>
                                              <option value="38">-  Life Scientist (e.g., Animal, Food, Soil, or Biological Scientist, Zoologist)</option>
                                              <option value="39">-  Physical Scientist (e.g., Astronomer, Physicist, Chemist, Hydrologist)</option>
                                              <option value="40">-  Religious Worker (e.g., Clergy, Director of Religious Activities or Education)</option>
                                              <option value="41">-  Social Scientist and Related Worker</option>
                                              <option value="42">-  Other Professional Occupation</option>
                                            </optgroup>
                                            <optgroup label="Office and Administrative Support Occupations:">
                                              <option value="43">-  Supervisor of Administrative Support Workers</option>
                                              <option value="44">-  Financial Clerk</option>
                                              <option value="45">-  Secretary or Administrative Assistant</option>
                                              <option value="46">-  Material Recording, Scheduling, and Dispatching Worker</option>
                                              <option value="47">-  Other Office and Administrative Support Occupation</option>
                                            </optgroup>
                                            <optgroup label="Services Occupations:">
                                              <option value="48">-  Protective Service (e.g., Fire Fighting, Police Officer, Correctional Officer)</option>
                                              <option value="49">-  Chef or Head Cook</option>
                                              <option value="50">-  Cook or Food Preparation Worker</option>
                                              <option value="51">-  Food and Beverage Serving Worker (e.g., Bartender, Waiter, Waitress)</option>
                                              <option value="52">-  Building and Grounds Cleaning and Maintenance</option>
                                              <option value="53">-  Personal Care and Service (e.g., Hairdresser, Flight Attendant, Concierge)</option>
                                              <option value="54">-  Sales Supervisor, Retail Sales</option>
                                              <option value="55">-  Retail Sales Worker</option>
                                              <option value="56">-  Insurance Sales Agent</option>
                                              <option value="57">-  Sales Representative</option>
                                              <option value="58">-  Real Estate Sales Agent</option>
                                              <option value="59">-  Other Services Occupation</option>
                                            </optgroup>
                                            <optgroup label="Agriculture, Maintenance, Repair, and Skilled Crafts Occupations:">
                                              <option value="60">-  Construction and Extraction (e.g., Construction Laborer, Electrician)</option>
                                              <option value="61">-  Farming, Fishing, and Forestry</option>
                                              <option value="62">-  Installation, Maintenance, and Repair</option>
                                              <option value="63">-  Production Occupations</option>
                                              <option value="64">-  Other Agriculture, Maintenance, Repair, and Skilled Crafts Occupation</option>
                                            </optgroup>
                                            <optgroup label="Transportation Occupations:">
                                              <option value="65">-  Aircraft Pilot or Flight Engineer</option>
                                              <option value="66">-  Motor Vehicle Operator (e.g., Ambulance, Bus, Taxi, or Truck Driver)</option>
                                              <option value="67">-  Other Transportation Occupation</option>
                                            </optgroup>
                                            <optgroup label="Other Occupations:">
                                              <option value="68">-  Military</option>
                                              <option value="69">-  Homemaker</option>
                                              <option value="70">-  Other Occupation</option>
                                              <option value="71">-  Don't Know</option>
                                              <option value="72">-  Not Applicable</option>
                                            </optgroup>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                 
                                  <div className="pb-5" data-wizard-type="step-content">
                                    
                                    <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left">
                                      <h3 className="font-weight-bolder text-dark font-size-h2">Employment Information</h3>
                                    </div>
                                    <div className="row">
                                    <div className="form-group mb-5 col-6">
                                      <label htmlFor="username" >Employment Status</label>
                                      <select className="form-control form-control-lg h-auto  rounded-lg">
                                        <option value="Single">-- select employment status--</option>
                                          <option value="Single">Employed</option>
                                          <option value="Married">Unemployed</option>
                                          <option value="Divorced">Self-employed</option>
                                          </select>
                                      </div>
                                      <div className="form-group mb-5 col-6">
                                        <label htmlFor="username" >Name of Employer</label>
                                        <input type="text" className="form-control form-control-lg h-auto rounded-lg" placeholder="Where do you work"/>
                                      </div>
                                      </div>

                                    <div className="row">
                                      <div className="col-xl-6">
                                        <div className="form-group">
                                          <label>Category of Industry</label>
                                          <select className="form-control form-control-lg h-auto  rounded-lg">
                                         
                                            <optgroup label="Healthcare Practitioners and Technical Occupations:">
                                              <option value="">  --</option>
                                              <option value="1">-  Chiropractor</option>
                                              <option value="2">-  Dentist</option>
                                              <option value="3">-  Dietitian or Nutritionist</option>
                                              <option value="4">-  Optometrist</option>
                                              <option value="5">-  Pharmacist</option>
                                              <option value="6">-  Physician</option>
                                              <option value="7">-  Physician Assistant</option>
                                              <option value="8">-  Podiatrist</option>
                                              <option value="9">-  Registered Nurse</option>
                                              <option value="10">-  Therapist</option>
                                              <option value="11">-  Veterinarian</option>
                                              <option value="12">-  Health Technologist or Technician</option>
                                              <option value="13">-  Other Healthcare Practitioners and Technical Occupation</option>
                                            </optgroup>
                                            <optgroup label="Healthcare Support Occupations:">
                                              <option value="14">-  Nursing, Psychiatric, or Home Health Aide</option>
                                              <option value="15">-  Occupational and Physical Therapist Assistant or Aide</option>
                                              <option value="16">-  Other Healthcare Support Occupation</option>
                                            </optgroup>
                                            <optgroup label="Business, Executive, Management, and Financial Occupations:">
                                              <option value="17">-  Chief Executive</option>
                                              <option value="18">-  General and Operations Manager</option>
                                              <option value="19">-  Advertising, Marketing, Promotions, Public Relations, and Sales Manager</option>
                                              <option value="20">-  Operations Specialties Manager (e.g., IT or HR Manager)</option>
                                              <option value="21">-  Construction Manager</option>
                                              <option value="22">-  Engineering Manager</option>
                                              <option value="23">-  Accountant, Auditor</option>
                                              <option value="24">-  Business Operations or Financial Specialist</option>
                                              <option value="25">-  Business Owner</option>
                                              <option value="26">-  Other Business, Executive, Management, Financial Occupation</option>
                                            </optgroup>
                                            <optgroup label="Architecture and Engineering Occupations:">
                                              <option value="27">-  Architect, Surveyor, or Cartographer</option>
                                              <option value="28">-  Engineer</option>
                                              <option value="29">-  Other Architecture and Engineering Occupation</option>
                                            </optgroup>
                                            <optgroup label="Education, Training, and Library Occupations:">
                                              <option value="30">-  Postsecondary Teacher (e.g., College Professor)</option>
                                              <option value="31">-  Primary, Secondary, or Special Education School Teacher</option>
                                              <option value="32">-  Other Teacher or Instructor</option>
                                              <option value="33">-  Other Education, Training, and Library Occupation</option>
                                            </optgroup>
                                            <optgroup label="Other Professional Occupations:">
                                              <option value="34">-  Arts, Design, Entertainment, Sports, and Media Occupations</option>
                                              <option value="35">-  Computer Specialist, Mathematical Science</option>
                                              <option value="36">-  Counselor, Social Worker, or Other Community and Social Service Specialist</option>
                                              <option value="37">-  Lawyer, Judge</option>
                                              <option value="38">-  Life Scientist (e.g., Animal, Food, Soil, or Biological Scientist, Zoologist)</option>
                                              <option value="39">-  Physical Scientist (e.g., Astronomer, Physicist, Chemist, Hydrologist)</option>
                                              <option value="40">-  Religious Worker (e.g., Clergy, Director of Religious Activities or Education)</option>
                                              <option value="41">-  Social Scientist and Related Worker</option>
                                              <option value="42">-  Other Professional Occupation</option>
                                            </optgroup>
                                            <optgroup label="Office and Administrative Support Occupations:">
                                              <option value="43">-  Supervisor of Administrative Support Workers</option>
                                              <option value="44">-  Financial Clerk</option>
                                              <option value="45">-  Secretary or Administrative Assistant</option>
                                              <option value="46">-  Material Recording, Scheduling, and Dispatching Worker</option>
                                              <option value="47">-  Other Office and Administrative Support Occupation</option>
                                            </optgroup>
                                            <optgroup label="Services Occupations:">
                                              <option value="48">-  Protective Service (e.g., Fire Fighting, Police Officer, Correctional Officer)</option>
                                              <option value="49">-  Chef or Head Cook</option>
                                              <option value="50">-  Cook or Food Preparation Worker</option>
                                              <option value="51">-  Food and Beverage Serving Worker (e.g., Bartender, Waiter, Waitress)</option>
                                              <option value="52">-  Building and Grounds Cleaning and Maintenance</option>
                                              <option value="53">-  Personal Care and Service (e.g., Hairdresser, Flight Attendant, Concierge)</option>
                                              <option value="54">-  Sales Supervisor, Retail Sales</option>
                                              <option value="55">-  Retail Sales Worker</option>
                                              <option value="56">-  Insurance Sales Agent</option>
                                              <option value="57">-  Sales Representative</option>
                                              <option value="58">-  Real Estate Sales Agent</option>
                                              <option value="59">-  Other Services Occupation</option>
                                            </optgroup>
                                            <optgroup label="Agriculture, Maintenance, Repair, and Skilled Crafts Occupations:">
                                              <option value="60">-  Construction and Extraction (e.g., Construction Laborer, Electrician)</option>
                                              <option value="61">-  Farming, Fishing, and Forestry</option>
                                              <option value="62">-  Installation, Maintenance, and Repair</option>
                                              <option value="63">-  Production Occupations</option>
                                              <option value="64">-  Other Agriculture, Maintenance, Repair, and Skilled Crafts Occupation</option>
                                            </optgroup>
                                            <optgroup label="Transportation Occupations:">
                                              <option value="65">-  Aircraft Pilot or Flight Engineer</option>
                                              <option value="66">-  Motor Vehicle Operator (e.g., Ambulance, Bus, Taxi, or Truck Driver)</option>
                                              <option value="67">-  Other Transportation Occupation</option>
                                            </optgroup>
                                            <optgroup label="Other Occupations:">
                                              <option value="68">-  Military</option>
                                              <option value="69">-  Homemaker</option>
                                              <option value="70">-  Other Occupation</option>
                                              <option value="71">-  Don't Know</option>
                                              <option value="72">-  Not Applicable</option>
                                            </optgroup>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="form-group mb-5 col-6">
                                        <label htmlFor="username" >Job Title</label>
                                        <input type="text" className="form-control form-control-lg h-auto rounded-lg" placeholder="What is your job title"/>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-xl-6">
                                        <div className="form-group">
                                          <label>Total Income</label>
                                          <input type="text" className="form-control form-control-lg h-auto  rounded-lg" name placeholder="What is your Total Income"  />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="pb-5" data-wizard-type="step-content">
                                    <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left">
                                      <h3>Regulatory Questions <small style={{fontSize: "9px"}}>(if you have never heard of these terms, it probably does not apply to you. Most people answer no)</small></h3>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-12 col-form-label"  style={{fontSize: "12px"}}>I am (or a household member, or a close associate of mine is) a politically exposed person or head of an international organization.</label>
                                    </div>
                                    <div className="form-group row">
                                    <label className="col-12 col-form-label"  style={{fontSize: "12px"}}>I am (or a household member) employed by an IIROC member firm.</label>
                                     
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-12 col-form-label"  style={{fontSize: "12px"}}>I am (or a household member) a director or senior officer of a publicly traded company, or own 10% or more of a publicly traded company.</label>
                                    </div>
                                    <div className="form-group row">
                                    <div className="col-12 col-form-label">
                                        <div className="checkbox-inline">
                                          <label className="checkbox checkbox-success">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Yes</label>
                                          <label className="checkbox checkbox-success ">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />No</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div></form>
                                <div className="pb-5" data-wizard-type="step-content">
                                  <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left">
                                    <h3 className="font-weight-bolder text-dark font-size-h2">Personal Finance Information</h3>
                                  </div>
                                  <form className="form">
                                  <div className="form-group row">
                                      <label className="col-5 col-form-label">How much do you have saved (CAD$)</label>
                                      <div className="col-7 col-form-label">
                                      <NumberFormat  
                                             prefix="$"
                                              mask="_"
                                              value={state.amountSaved}
                                              onChange={handleWizardChange}
                                              id="amountSaved"
                                              thousandSeparator={true}
                                              name="amountSaved"
                                              className="form-control form-control-lg h-auto rounded-lg"
                                              placeholder="$250,000.00"
                                          />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-5 col-form-label">What is the value of your property and assets(CAD$)</label>
                                      <div className="col-7 col-form-label">
                                      <NumberFormat  
                                             prefix="$"
                                              mask="_"
                                              value={state.propValue}
                                              onChange={handleWizardChange}
                                              id="propValue"
                                              thousandSeparator={true}
                                              name="propValue"
                                              className="form-control form-control-lg h-auto rounded-lg"
                                              placeholder="$250,000.00"
                                          />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-5 col-form-label">What is the value of your debts(CAD$)</label>
                                      <div className="col-7 col-form-label">
                                      <NumberFormat  
                                             prefix="$"
                                              mask="_"
                                              value={state.debtValue}
                                              onChange={handleWizardChange}
                                              id="debtValue"
                                              thousandSeparator={true}
                                              name="debtValue"
                                              className="form-control form-control-lg h-auto rounded-lg"
                                              placeholder="$250,000.00"
                                          />
                                      </div>
                                    </div>
                                  </form>
                                </div>
                                <div className="pb-5" data-wizard-type="step-content">
                                  <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left">
                                    <h3 className="font-weight-bolder text-dark font-size-h2">Setup your Account</h3>
                                  </div>
                                  <form className="form">
                                    <div className="form-group row">
                                      <label className="col-9 col-form-label">Do you have an existing investment account you would like to transfer</label>
                                      <div className="col-12 col-form-label">
                                        <div className="checkbox-inline">
                                          <label className="checkbox checkbox-success">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Yes, i'd like to  transfer an existing investment account</label>
                                          <label className="checkbox checkbox-success ">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />No, i'll start with a new piggyalpha account</label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-9 col-form-label">What type of account would you like to open</label>
                                      <div className="col-9 col-form-label">
                                        <label className="form-label" style={{fontSize: '10px'}}>For investing</label>
                                        <div className="checkbox-inline">
                                          <label className="checkbox checkbox-success">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />RRSP</label>
                                          <label className="checkbox checkbox-success ">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />TFSA</label>
                                          <label className="checkbox checkbox-success ">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Non-registered</label>
                                          <label className="checkbox checkbox-success ">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Joint</label>
                                          <label className="checkbox checkbox-success ">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />RESP</label>
                                        </div>
                                      </div>
                                    </div>
                                    <a type="button" href="transfer1" className="btn btn-primary font-weight-bolder font-size-h6 pr-8 pl-6 py-4 my-3 mr-3">
                                      Transfer</a>
                                  </form>
                                </div>
                                <div className="pb-5" data-wizard-type="step-content">
                                  <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left">
                                    <h3 className="font-weight-bolder text-dark font-size-h2">Preview</h3>
                                    <div className="form-group">
                                      <label className="col-9 col-form-label">Personal Information</label>
                                      <span className="fa fa-pencil-alt" style={{float: 'right'}} />
                                    </div>
                                  </div>
                                  <div className="separator separator-solid my-7" />
                                  <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left">
                                    <div className="form-group">
                                      <label className="col-9 col-form-label">Employment Information</label>
                                      <span className="fa fa-pencil-alt" style={{float: 'right'}} />
                                    </div>
                                  </div>
                                  <div className="separator separator-solid my-7" />
                                  <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left">
                                    <div className="form-group">
                                      <label className="col-9 col-form-label">Anti-Money Laundering</label>
                                      <span className="fa fa-pencil-alt" style={{float: 'right'}} />
                                    </div>
                                  </div>
                                  <div className="separator separator-solid my-7" />
                                  <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left">
                                    <div className="form-group">
                                      <label className="col-9 col-form-label">Client Investor Profile</label>
                                      <span className="fa fa-pencil-alt" style={{float: 'right'}} />
                                    </div>
                                  </div>
                                  <div className="separator separator-solid my-7" />
                                  <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left">
                                    <div className="form-group">
                                      <label className="col-9 col-form-label">Accounts</label>
                                      <span className="fa fa-pencil-alt" style={{float: 'right'}} />
                                    </div>
                                  </div>
                                  <div className="separator separator-solid my-7" />
                                  <div className="mt-10 text-center">
                                    <a href="0#">Review Your agreements</a>
                                    <br />
                                    <p className="opacity-70 mr-4 text-muted font-weight-bold" style={{marginBottom: '-2px', fontSize: '13px'}}>By checking the box below, you acknowledge that you have read and agreed to the terms and </p>
                                    <p className="opacity-70 mr-4 text-muted font-weight-bold" style={{fontSize: '13px'}}>conditions and agree to use electronic records and signatures. </p>
                                    <label className="checkbox checkbox-success text-muted font-weight-bold">
                                      <input type="checkbox" name="Checkboxes5" />
                                      <span />&nbsp; I agree to the Client Agreement and as of today, I certify that the information I have provided in the following is correct. </label>
                                  </div>
                                </div>
                                <div>
                                <div className="d-flex justify-content-between pt-7">
                                    <div className="mr-2">
                                    <a type="button" className="btn btn-light-primary font-weight-bolder font-size-h6 pr-8 pl-6 py-4 my-3 mr-3" href="FamilyInfoo">
                                        <span className="svg-icon svg-icon-md mr-2">
                                          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                              <polygon points="0 0 24 0 24 24 0 24" />
                                              <rect fill="#000000" opacity="0.3" transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000)" x={14} y={7} width={2} height={10} rx={1} />
                                              <path d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997)" />
                                            </g>
                                          </svg>
                                        </span>Previous</a>
                                    </div>
                                    <div>
                                      <button type="button" className="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3" data-wizard-type="action-submit" data-toggle="modal" data-target="#exampleModalScrollable">Submit
                                        <span className="svg-icon svg-icon-md ml-2">
                                          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                              <polygon points="0 0 24 0 24 24 0 24" />
                                              <rect fill="#000000" opacity="0.3" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width={2} height={9} rx={1} />
                                              <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                            </g>
                                          </svg>
                                        </span></button>
                                      <button onClick={handleWizardClick} type="button" className="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3">Next
                                        <span className="svg-icon svg-icon-md ml-2">
                                          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                              <polygon points="0 0 24 0 24 24 0 24" />
                                              <rect fill="#000000" opacity="0.3" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width={2} height={9} rx={1} />
                                              <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                            </g>
                                          </svg>
                                        </span></button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal fade" id="exampleModalScrollable" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-default" role="document">
                  <div className="modal-content">
                    <div className="modal-body" style={{height: 'auto', width: 'auto'}}>
                      <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left">
                        <div className="d-flex flex-center">
                          <a href="0#">
                            <img src="assets/media/favicon.png" className="max-h-75px" alt="" />
                          </a>
                        </div>
                        <div className style={{padding: '35px', textAlign: 'center'}}>
                          <h3 className="font-weight-bold text-dark font-size-h2 text-center">Congratulations</h3>
                          <p className="opacity-70" style={{marginBottom: '-2px', fontSize: '13px'}}>You have successfully setup your account profile, we just need to start building out your personalized portfolio so we can start growing your money and fulfilling your goals.</p>
                          <br />
                          <center><a type="button" href="funding" className="btn btn-primary text-center font-weight-bold">Fund your account</a></center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
              <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                  <Modal.Title>Missing fields</Modal.Title>
                </Modal.Header>
                <Modal.Body>Please enter all information correctly before proceeding.</Modal.Body>
                <Modal.Footer>
                  <Button variant="danger" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
              </div>
            </div>
         

    );
};

export default InvestInfo;
