import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Button,Alert, dismissible } from 'react-bootstrap';
import { send } from 'emailjs-com';

function SignUp(props){

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [incorrectPassword, setPShow] = useState(false);
  const PClose = () => setPShow(false);
  const PShow = () => setPShow(true);

  const [PasswordCombination, setPCShow] = useState(false);
  const PCClose = () => setPCShow(false);
  const PCShow = () => setPCShow(true);

  const [successfulRegshow, setRegShow] = useState(false);
  const RegClose = () => setRegShow(false);
  const RegShow = () => setRegShow(true);
  

  var crypto = require("crypto");
  
  const [state , setState] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    code: crypto.randomBytes(2).toString('hex'),
    name: 'User',
    from_email: 'noreply@alphawealth.io',
    reply_to: 'noreply@alphawealth.io',
        
})


const [submitted, setSubmitted] = useState(false);


const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
}


const [authentication, setauthentication] = useState('');

const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");


// Add properties to it



const handleSubmitClick = (e) => {
 
  setSubmitted(true);
  if (!state.email){
    setShow(true)
  }else if(!state.password){
    setShow(true)
  }else if(!state.confirmPassword){
    setShow(true)
  }else if (state.password != state.confirmPassword){
    setPShow(true);
  }else if(!strongRegex.test(state.password)){
      setPCShow(true);
  }else if (state.email && state.password && state.confirmPassword) {
  send(
    'service_dlz1tld',
    'template_r6oqnkr',
    state,
    'user_Sxr7g42k070zIYiAPxgtQ',
  )
  .then((response) => {
    console.log('SUCCESS!', response.status, response.text);
  })
  .catch((err) => {
    console.log('FAILED...', err);
    console.log(state.email);
  });
   
    setRegShow(true);
  }
}

const NewCode = (e) => {
  
  send(
    'service_dlz1tld',
    'template_r6oqnkr',
    'user_Sxr7g42k070zIYiAPxgtQ',
    state,
  )
  .then((response) => {
    console.log('SUCCESS!', response.status, response.text);
  })
  .catch((err) => {
    console.log('FAILED...', err);
    console.log(state.email);
  });
}


const dashboardProceed = (e) => {
  e.preventDefault();
  if(!authentication){
    console.log('no entry');
    console.log(state.code);

  }else if(authentication != state.code){
    console.log('not correct')
    console.log(state.code);

  }else{
  history.push('dashboard1');
  setRegShow(false);
    state.email = '' 
    state.password=''
    state.confirmPassword = ''
}
}

  let history = useHistory();

if (!sessionStorage.getItem('auth-token')) {
  console.log('no auth token set');
  history.push('/');
  //do something like redirect to login page
} else {
  const authToken = 'piggyalphalimited2209827462713182219@quiqw0okqp-pl';
  if (sessionStorage.getItem('auth-token') === authToken) {
      console.log('good token. Log in.')
      //do something like redirect to todo page
  } else {
      console.log('bad token.')
      history.push('/');
      //do something like redirect to login page
  }
}
    return (
            <div className="d-flex flex-column flex-root">
              <div className="login login-4 login-signin-on d-flex flex-row-fluid" id="kt_login">
                <div className="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat" style={{backgroundColor: 'white'}}>
                  <div className="login-form p-7 position-relative overflow-hidden">
                    <div className="d-flex flex-center">
                      <a href="0#">
                        <img src="assets/media/logos/logo.png" className="max-h-75px" alt="" />
                      </a>
                    </div>
                    <div className="card card-board" style={{backgroundColor: '#F0F1F4', maxWidth: '900px'}}>
                      <div className="card-body">
                        <div className="mb-9">
                          <h3 className="font-weight-bold">Get Started in a few Minutes</h3>
                        </div>
                        <div className="row">
                          <div className="form-group mb-5 col-12">
                            <label htmlFor="username">Email</label>
                            <input className="form-control h-auto form-control-solid py-4 px-8" value={state.email} onChange={handleChange}  style={{backgroundColor: 'white'}} type="email" id="email" />
                            <span className="text-muted">Please enter your email</span>
                          </div>
                        </div>
                        <br></br>
                        <div className="row">
                          <div className="form-group mb-5 col-12">
                            <label htmlFor="username">Create Password</label>
                            <input className="form-control h-auto form-control-solid py-4 px-8" style={{backgroundColor: 'white'}} value={state.password} onChange={handleChange}  type="password" id="password"/>
                            <span className="text-muted">Please enter your password</span>
                          </div>
                          </div>
                          <br></br>
                          <div className="row">
                          <div className="form-group mb-5 col-12">
                            <label htmlFor="username">Confirm Password</label>
                            <input className="form-control h-auto form-control-solid py-4 px-8" style={{backgroundColor: 'white'}} type="password" value={state.confirmPassword} onChange={handleChange} id="confirmPassword" name="confirmPassword" />
                            <span className="text-muted">Retype your password</span>
                          </div>
                        </div>
                        <br></br>
                       <div className="text-center">
                         <button onClick={handleSubmitClick} className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4">Sign Up</button>
                         </div>
                      </div>
                    </div>
                    <div className="mt-10 text-center">
                      <a href="0#" id="kt_login_signup" className="text-muted text-hover-primary font-weight-bold">By clicking <span style={{color: '#536DFE'}}>"Sign Up"</span>, you agree to  PiggyAlpha's </a><a href="0#">Terms of Use and Privacy Policy.</a>
                    </div>
                    <br></br>

               <div className="mt-10 text-center">
                  <span className="opacity-70 mr-4" style={{color: '#536DFE',fontWeight: 'bold'}}><a href="https://alphawealth.io/terms">Terms</a> </span>
                  <span className="opacity-70 mr-4" style={{color: '#536DFE', fontWeight: 'bold'}}><a href="https://alphawealth.io/#contact">Contact us </a></span>
                </div>
                  </div>
                </div>
              </div>

<div>

     <Modal show={show} onHide={handleClose}>
        <Modal.Header >
          <Modal.Title>Missing fields</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please enter all information correctly before proceeding.</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={incorrectPassword} onHide={PClose}>
        <Modal.Header >
          <Modal.Title>Password Mismatch</Modal.Title>
        </Modal.Header>
        <Modal.Body> New Password and Confirm Password must match..</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={PClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={PasswordCombination} onHide={PCClose}>
        <Modal.Header >
          <Modal.Title>Wrong password combination</Modal.Title>
        </Modal.Header>
        <Modal.Body> Password must be a combination of at least one uppercase Letter, one lowercase letter, One number and a special character.</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={PCClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={successfulRegshow} onHide={RegClose}  keyboard={false}>
        <Modal.Header>
          <Modal.Title>Authorization Code</Modal.Title>
        
          <Button style={{textAlign: 'left'}} variant="success" onClick={NewCode}>
            Generate new code
          </Button>
        </Modal.Header>
        <Modal.Body>
        <input className="form-control h-auto form-control-solid py-4 px-8" value={state.authentication}  onChange={e => setauthentication(e.target.value)} placeholder="Please enter the Authorization code sent to your mail"  style={{backgroundColor: 'white'}}/>
        </Modal.Body>
        <Modal.Footer>
        
        <Button style={{textAlign: 'right'}} variant="danger" onClick={RegClose}>
            Close
          </Button>
          <Button style={{textAlign: 'right'}} onClick={dashboardProceed}  variant="primary">Submit</Button>

        </Modal.Footer>
      </Modal>
    

      </div>
    
</div>
         
    );
};

export default SignUp;
