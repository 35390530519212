import React from 'react'; 
import { useHistory } from 'react-router-dom';


function Transfer2(){
  const history = useHistory();


if (!sessionStorage.getItem('auth-token')) {
  console.log('no auth token set');
  history.push('/');
  //do something like redirect to login page
} else {
  const authToken = 'piggyalphalimited2209827462713182219@quiqw0okqp-pl';
  if (sessionStorage.getItem('auth-token') === authToken) {
      console.log('good token. Log in.')
      //do something like redirect to todo page
  } else {
      console.log('bad token.')
      history.push('/');
      //do something like redirect to login page
  }
}
    return (
    <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-row flex-column-fluid page">
          <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
            <div id="kt_header" className="header flex-column header-fixed" style={{backgroundColor: '#E4F9FF'}}>
              <div className="header-top">
                <div className="container">
                  <div className="d-none d-lg-flex align-items-center mr-3">
                    <a href="dashboard" className="mr-20">
                      <img alt="Logo" src="assets/media/logos/piggy-1.png" className="max-h-35px" />
                    </a>
                  </div>
                  <div className="topbar" style={{backgroundColor: '#E4F9FF'}}>
                    <div className="topbar-item" style={{backgroundColor: '#E4F9FF'}}>
                      <div className="btn btn-icon btn-hover-transparent-white w-sm-auto d-flex align-items-center btn-lg px-2" sty id="kt_quick_user_toggle">
                        <div className="d-flex flex-column text-right pr-sm-3">
                          <span className="opacity-50 font-weight-bold font-size-sm d-none d-sm-inline" style={{color: 'black'}}>Hi</span>
                          <span className="font-weight-bolder font-size-sm d-none d-sm-inline" style={{color: 'black'}}></span>
                        </div>
                        <span className="symbol symbol-35" style={{backgroundColor: '#536DFE'}}>
                          <span className="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-30"><i className="far fa-bell" /></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="header-bottom">
                <div className="container">
                  <div className="header-navs header-navs-left" id="kt_header_navs">
                    <div className="tab-content">
                      <div className="tab-pane py-5 p-lg-0 show active" id="kt_header_tab_1">
                        <div id="kt_header_menu" className="header-menu header-menu-mobile header-menu-layout-default">
                          <ul className="menu-nav">
                            <li className="menu-item" >
                              <a href="dashboard" className="menu-link">
                                <span className="menu-text"> <i className="fas fa-chart-line" />&nbsp; Portfolio</span>
                              </a>
                            </li>
                            <li className="menu-item" >
                              <a href="activity" className="menu-link">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span className="menu-text"><i className="fas fa-bars" />&nbsp; Activity</span>
                                <span className="menu-desc" />
                                <i className="menu-arrow" />
                              </a>
                            </li>
                            <li className="menu-item menu-item-submenu menu-item-rel" data-menu-toggle="click" >
                              <a href="funding" className="menu-link menu-toggle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span className="menu-text" style={{color: '#3699FF'}}><i className="la la-plus-circle" />&nbsp;Funding</span>
                                <span className="menu-desc" />
                                <i className="menu-arrow" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
    <div className="content d-flex flex-column flex-column-fluid bg-gray" id="kt_content">
        <div className="subheader py-2 py-lg-6 subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <div className="d-flex align-items-center flex-wrap mr-1">
              <div className="d-flex align-items-baseline flex-wrap mr-5">
                <h5 className="text-dark font-weight-bold my-1 mr-5">Transfer Existing Account</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column-fluid">
          <div className="container">
            <div className="card card-custom">
              <div className="card-body p-0">
                <d v className="wizard wizard-3" id="kt_wizard_v3" data-wizard-state="step-first" data-wizard-clickable="true">
                  <div className="wizard-nav">
                    <div className="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
                      <div className="wizard-step" data-wizard-type="step">
                        <a className="wizard-label" href="funding">
                          <h3 className="wizard-title">
                            Add or Move Funds</h3>
                          <div className="wizard-bar" />
                        </a>
                      </div>
                      <div className="wizard-step" data-wizard-type="step">
                        <div className="wizard-label">
                          <h3 className="wizard-title">
                            <span />Withdraw Funds</h3>
                          <div className="wizard-bar" />
                        </div>
                      </div>
                      <div className="wizard-step" data-wizard-type="step" data-wizard-state="current">
                        <div className="wizard-label">
                          <a href="transfer1"><h3 className="wizard-title">
                              Transfer Account</h3></a>
                          <div className="wizard-bar" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center py-10 px-8 py-lg-12 px-lg-10">
                    <div className="col-xl-12 col-xxl-7">
                      <form className="form" style={{padding: '-40px', paddingLeft: '30px', paddingRight: '30px'}}>
                        <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                          <div className="row">
                            <div className="form-group col-xl-6">
                              <label style={{fontWeight: 'bolder'}}>What is your RRSP Account Number</label>
                              <p><small>You can find these details on your account statement</small></p>
                              <input type="text" className="form-control form-control-solid h-auto p-5 border-0 rounded-lg" name placeholder="Account Number"  />
                            </div>
                            <div className="form-group col-xl-6">
                              <label style={{fontWeight: 'bolder'}}>What is the estimated value of the account</label>
                              <p><small>You can find these details on your account statement</small></p>
                              <input type="text" className="form-control form-control-solid h-auto p-5 border-0 rounded-lg" name placeholder="$0.00"  />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-12">
                              <div className="form-group">
                                <label style={{fontWeight: 'bolder'}}>Upload your PDF statement or screenshot</label>
                                <p><small>Presenting this information helps speed up the process and prevent errors.</small></p>
                                <input type="file" className="form-control form-control-solid h-auto p-5 border-0 rounded-lg" name placeholder="Upload ID"  />
                              </div>
                            </div>
                          </div>
                          <a  href="transfer1" type="button" style={{float: 'left'}} className="btn btn-danger font-weight-bold text-uppercase px-9 py-4">Back
                          </a>
                          <button type="button" style={{float: 'right'}} data-toggle="modal" data-dismiss="modal" data-target="#exampleModalScrollable3" className="btn btn-primary font-weight-bold text-uppercase px-9 py-4">Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </d></div>
            </div>
          </div>
        </div>
      </div>
            </div>
        </div>
        <div>
        <div className="modal fade" id="exampleModalScrollable3" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-body" style={{height: 'auto', width: 'auto'}}>
                <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left">
                  <div className="d-flex flex-center">
                    <a href="0#">
                      <img src="assets/media/favicon.png" className="max-h-75px" alt="" />
                    </a>
                  </div>
                  <div className style={{padding: '35px', textAlign: 'center'}}>
                    <h3 className="font-weight-bold text-dark font-size-h3 text-center">You have successfully transferred your RRSP Account</h3>
                    <p className="opacity-70" style={{marginBottom: '-2px', fontSize: '13px'}}>You are ready to start contributing to your future goals.</p>
                    <br />
                    <center><a type="button" href="dashboard" className="btn btn-primary text-center font-weight-bold">Portfolio</a></center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="kt_quick_user" className="offcanvas offcanvas-right p-10">
          <div className="offcanvas-content pr-5 mr-n5">
            <div className="d-flex align-items-center mt-5">
              <div className="symbol symbol-70 mr-5">
                <div className="symbol-label" style={{backgroundImage: 'url("assets2/media/users/300_21.jpg")'}} />
                <i className="symbol-badge bg-success" />
              </div>
              <div className="d-flex flex-column">
                <div className="text-muted mt-1">Sean Combs</div>
                <div className="navi mt-2">
                  <a href="0#" className="btn btn-sm btn-light-primary font-weight-bolder py-2 px-5">Sign Out</a>
                </div>
              </div>
            </div>
            <div className="separator separator-dashed mt-8 mb-5" />
            <div className="navi navi-spacer-x-0 p-0">
              <a href="0#" className="navi-item">
                <div className="navi-link">
                  <div className="navi-text">
                    <div className="font-weight-bold">My Documents</div>
                    <div className="text-muted">Account settings and more</div>
                  </div>
                </div>
              </a>
              <a href="0#" className="navi-item">
                <div className="navi-link">
                  <div className="navi-text">
                    <div className="font-weight-bold">Settings</div>
                    <div className="text-muted">Setup and manage your PiggyAlphaaccount</div>
                  </div>
                </div>
              </a>
              <a href="0#" className="navi-item">
                <div className="navi-link">
                  <div className="navi-text">
                    <div className="font-weight-bold">Help</div>
                    <div className="text-muted"> Contact our customer support for help </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="separator separator-dashed my-7" />
            <div>
              <h5 className="mb-5">Recent Notifications</h5>
              <div className="d-flex align-items-center bg-light-warning rounded p-5 gutter-b">
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <a href="0#" className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1">Something goes here</a>
                  <span className="text-muted font-size-sm">Trade xxx on July 10</span>
                </div>
                <span className="font-weight-bolder text-warning py-1 font-size-lg">+10%</span>
              </div>
              <div className="d-flex align-items-center bg-light-success rounded p-5 gutter-b">
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <a href="0#" className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1">Something goes here</a>
                  <span className="text-muted font-size-sm">Trade xxx on July 10</span>
                </div>
                <span className="font-weight-bolder text-success py-1 font-size-lg">+10%</span>
              </div>
              <div className="d-flex align-items-center bg-light-danger rounded p-5 gutter-b">
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <a href="0#" className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1">Something goes here</a>
                  <span className="text-muted font-size-sm">Trade xxx on July 10</span>
                </div>
                <span className="font-weight-bolder text-danger py-1 font-size-lg">+10%</span>
              </div>
              <div className="d-flex align-items-center bg-light-info rounded p-5">
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <a href="0#" className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1">Something goes here</a>
                  <span className="text-muted font-size-sm">Trade xxx on July 10</span>
                </div>
                <span className="font-weight-bolder text-info py-1 font-size-lg">+8%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>    
    
       
    );
};

export default Transfer2;
