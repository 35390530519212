import React, { useState } from 'react';
import { Modal, Button,Alert} from 'react-bootstrap';
import NumberFormat from "react-number-format";
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';


function Wizard(props){


const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const [state , setState] = useState({
  firstname: sessionStorage.getItem('firstname'), 
  lastname: sessionStorage.getItem('lastname'), 
  middlename: sessionStorage.getItem('middlename'), 
  ssn: sessionStorage.getItem('ssn'), 
  birthday: sessionStorage.getItem('birthday'), 
  phone: sessionStorage.getItem('phone'), 
})

const [submitted, setSubmitted] = useState(false);


const handleWizardChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
}


const handleWizardClick = (e) => {
 
  console.log(state.firstname);
  console.log(state.lastname);
  console.log(state.phone);
  console.log(state.birthday);
  console.log(state.ssn);
  if(!state.firstname){
    setShow(true)
  }else if(!state.ssn){
    setShow(true)
  }else if(!state.lastname){
    setShow(true)
  }else if(!state.phone){
    setShow(true)
  }else if(!state.birthday){
    setShow(true)
  }else if (state.firstname && state.ssn && state.lastname && state.phone && state.birthday) {
    history.push('PersonalInfo');
    sessionStorage.setItem('firstname', state.firstname);
    sessionStorage.setItem('middlename', state.middlename);
    sessionStorage.setItem('lastname', state.lastname);
    sessionStorage.setItem('phone', state.phone);
    sessionStorage.setItem('ssn', state.ssn);
    sessionStorage.setItem('birthday', state.birthday);
  } 

}
  const history = useHistory();



if (!sessionStorage.getItem('auth-token')) {
  console.log('no auth token set');
  history.push('/');
  //do something like redirect to login page
} else {
  const authToken = 'piggyalphalimited2209827462713182219@quiqw0okqp-pl';
  if (sessionStorage.getItem('auth-token') === authToken) {
      console.log('good token. Log in.')
      //do something like redirect to todo page
  } else {
      console.log('bad token.')
      history.push('/');
      //do something like redirect to login page
  }
}
    return (
      
            <div className="d-flex flex-column flex-root">
              <div className="d-flex flex-row flex-column-fluid page">
                <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                  <div id="kt_header" className="header flex-column header-fixed" style={{backgroundColor: '#E4F9FF'}}>
                    <div className="header-top">
                      <div className="container">
                        <div className="d-none d-lg-flex align-items-center mr-3">
                          <a href="dashboard" className="mr-20">
                            <img alt="Logo" src="assets/media/logos/piggy-1.png" className="max-h-35px" />
                          </a>
                        </div>
                        <div className="topbar" style={{backgroundColor: '#E4F9FF'}}>
                          <div className="topbar-item" style={{backgroundColor: '#E4F9FF'}}>
                            <div className="btn btn-icon btn-hover-transparent-white w-sm-auto d-flex align-items-center btn-lg px-2" sty id="kt_quick_user_toggle">
                              <div className="d-flex flex-column text-right pr`-sm-3">
                                <span className="opacity-50 font-weight-bold font-size-sm d-none d-sm-inline" style={{color: 'black'}}>Hi</span>
                                <span className="font-weight-bolder font-size-sm d-none d-sm-inline" style={{color: 'black'}}></span>
                              </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <span className="symbol symbol-35" style={{backgroundColor: '#536DFE'}}>
                                <span className="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-30"><i className="far fa-bell" /></span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="header-bottom">
                      <div className="container">
                        <div className="header-navs header-navs-left" id="kt_header_navs">
                          <div className="tab-content">
                            <div className="tab-pane py-5 p-lg-0 show active" id="kt_header_tab_1">
                              <div id="kt_header_menu" className="header-menu header-menu-mobile header-menu-layout-default">
                                <ul className="menu-nav">
                                  <li className="menu-item" >
                                    <a href className="menu-link">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <span className="menu-text"> <i className="fas fa-chart-line" />&nbsp; Portfolio</span>
                                    </a>
                                  </li>
                                  <li className="menu-item menu-item-submenu menu-item-rel" data-menu-toggle="click" >
                                    <a href="0#" className="menu-link menu-toggle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <span className="menu-text"><i className="fas fa-bars" />&nbsp; Activity</span>
                                      <span className="menu-desc" />
                                      <i className="menu-arrow" />
                                    </a>
                                  </li>
                                  <li className="menu-item menu-item-submenu menu-item-rel" data-menu-toggle="click" >
                                    <a href="0#" className="menu-link menu-toggle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <span className="menu-text"><i className="la la-plus-circle" />&nbsp;Funding</span>
                                      <span className="menu-desc" />
                                      <i className="menu-arrow" />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <div className="content d-flex flex-column flex-column-fluid" id="kt_content" style={{backgroundColor: '#f2f3f7'}} >
                    <div className="subheader py-2 py-lg-6 subheader-transparent" id="kt_subheader">
                      <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                        <div className="d-flex align-items-center flex-wrap mr-1">
                          <div className="d-flex align-items-baseline flex-wrap mr-5">
                            <h5 className="text-dark font-weight-bold my-1 mr-5">Setup your profile</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column-fluid">
                      <div className="container">
                        <div className="card card-custom">
                          <div className="card-body p-0">
                            <div className="wizard wizard-6 d-flex flex-column flex-lg-row flex-column-fluid" id="kt_wizard">
                              <div className="wizard-content d-flex flex-column mx-auto py-10 py-lg-20 w-100 w-md-700px">
                                <div className="d-flex flex-column-auto flex-column ">
                                  <div className="wizard-nav pb-lg-10 pb-3 d-flex flex-column align-items-center align-items-md-start">
                                    <div className="wizard-steps d-flex flex-column flex-md-row">
                                      <div className="wizard-step flex-grow-1 flex-basis-0" data-wizard-type="step" data-wizard-state="current">
                                        <div className="wizard-wrapper pr-lg-7 pr-5">
                                          <div className="wizard-icon">
                                            <i className="wizard-check ki ki-check" />
                                            <span className="wizard-number">1</span>
                                          </div>
                                          <div className="wizard-label mr-3">
                                            <div className="wizard-desc">Personal details</div>
                                          </div>
                                          <span className="svg-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24" />
                                                <rect fill="#000000" opacity="0.3" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width={2} height={9} rx={1} />
                                                <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                              </g>
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="wizard-step flex-grow-1 flex-basis-0" data-wizard-type="step">
                                        <div className="wizard-wrapper pr-lg-7 pr-5">
                                          <div className="wizard-icon">
                                            <i className="wizard-check ki ki-check" />
                                            <span className="wizard-number">2</span>
                                          </div>
                                          <div className="wizard-label mr-3">
                                            <div className="wizard-desc">Employment information</div>
                                          </div>
                                          <span className="svg-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24" />
                                                <rect fill="#000000" opacity="0.3" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width={2} height={9} rx={1} />
                                                <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                              </g>
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="wizard-step flex-grow-1 flex-basis-0" data-wizard-type="step">
                                        <div className="wizard-wrapper pr-lg-7 pr-5">
                                          <div className="wizard-icon">
                                            <i className="wizard-check ki ki-check" />
                                            <span className="wizard-number">3</span>
                                          </div>
                                          <div className="wizard-label mr-3">
                                            <div className="wizard-desc">Regulatory Questions</div>
                                          </div>
                                          <span className="svg-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24" />
                                                <rect fill="#000000" opacity="0.3" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width={2} height={9} rx={1} />
                                                <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                              </g>
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="wizard-step flex-grow-1 flex-basis-0" data-wizard-type="step">
                                        <div className="wizard-wrapper pr-lg-7 pr-5">
                                          <div className="wizard-icon">
                                            <i className="wizard-check ki ki-check" />
                                            <span className="wizard-number">4</span>
                                          </div>
                                          <div className="wizard-label mr-3">
                                            <div className="wizard-desc">Client Investor Profile</div>
                                          </div>
                                          <span className="svg-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24" />
                                                <rect fill="#000000" opacity="0.3" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width={2} height={9} rx={1} />
                                                <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                              </g>
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="wizard-step flex-grow-1 flex-basis-0" data-wizard-type="step">
                                        <div className="wizard-wrapper pr-lg-7 pr-5">
                                          <div className="wizard-icon">
                                            <i className="wizard-check ki ki-check" />
                                            <span className="wizard-number">5</span>
                                          </div>
                                          <div className="wizard-label mr-3">
                                            <div className="wizard-desc">Setup Account</div>
                                          </div>
                                          <span className="svg-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24" />
                                                <rect fill="#000000" opacity="0.3" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width={2} height={9} rx={1} />
                                                <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                              </g>
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="wizard-step flex-grow-1 flex-basis-0" data-wizard-type="step">
                                        <div className="wizard-wrapper pr-lg-7 pr-5">
                                          <div className="wizard-icon">
                                            <i className="wizard-check ki ki-check" />
                                            <span className="wizard-number">6</span>
                                          </div>
                                          <div className="wizard-label mr-3">
                                            <div className="wizard-desc">Preview and Submit</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <form className="px-10" noValidate="novalidate" id="kt_form">
                                  <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                    <div className="pb-10 pb-lg-12 text-center text-md-left">
                                      <h3 className="font-weight-bolder text-dark font-size-h2">Personal Details</h3>
                                    </div>
                                    <div className="row">
                                      <div className="form-group mb-5 col-6">
                                        <label htmlFor="username" >Firstname <small>Legal first (given) name</small></label>
                                        <input type="text" value={state.firstname} onChange={handleWizardChange} id="firstname" name="firstname" className="form-control form-control-lg h-auto rounded-lg" placeholder="Please enter your firstname"/>
                                      </div>

                                      <div className="form-group mb-5 col-6">
                                        <label htmlFor="username" >Middlename <small>Legal middle (given) name</small></label>
                                        <input type="text" value={state.middlename} onChange={handleWizardChange} id="middlename" name="middlename" className="form-control form-control-lg h-auto   rounded-lg" placeholder="Please enter your middlename"/>
                                      </div>
                                      
                                     
                                    </div>
                                    <div className="row">
                                    <div className="form-group mb-5 col-6">
                                        <label htmlFor="username" >Lastname <small>Legal last (given) name</small></label>
                                        <input type="text" value={state.lastname} onChange={handleWizardChange} id="lastname" name="lastname" className="form-control form-control-lg h-auto   rounded-lg" placeholder="Please enter your lastname"/>
                                      </div>
                                      
                                      <div className="form-group mb-5 col-6">
                                        <label htmlFor="username" >Phone number </label>
                                        <NumberFormat  
                                              format="+1 (###) ###-####"
                                              mask="_"
                                              value={state.phone}
                                              onChange={handleWizardChange}
                                              id="phone"
                                              name="phone"
                                              className="form-control form-control-lg h-auto rounded-lg"
                                              placeholder="Please enter your phone number"
                                          />
                                      </div>
                                      
                                    </div>
                                    <div className="row">
                                    <div className="form-group mb-5 col-6">
                                        <label htmlFor="username" >Social Insurance Number <i className="fa fa-info-circle"></i> </label>
                                         <NumberFormat  
                                              format="###-###-###"
                                              mask="_"
                                              value={state.ssn}
                                              onChange={handleWizardChange}
                                              id="ssn"
                                              name="ssn"
                                              className="form-control form-control-lg h-auto rounded-lg"
                                              placeholder="xxx-xxx-xxx"
                                          />
                                        </div>

                                      <div className="form-group mb-5 col-6">
                                        <label htmlFor="username" >Date of Birth</label>
                                        <input type="date" value={state.birthday} onChange={handleWizardChange} min="2007-01-01" id="birthday" name="birthday" className="form-control form-control-lg h-auto rounded-lg"/>
                                      </div>
                                    </div>
                                  </div>
                                 
                                 
                                 
                                 
                                 
                                 
                                  <div className="pb-5" data-wizard-type="step-content">
                                    
                                    <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left">
                                      <h3 className="font-weight-bolder text-dark font-size-h2">Employment Details</h3>
                                    </div>
                                    <div className="row">
                                      <div className="col-xl-6">
                                        <div className="form-group">
                                          <label>Employment Status</label>
                                          <input type="text" className="form-control form-control-lg h-auto  rounded-lg" name placeholder="What is your Employment Status"  />
                                        </div>
                                      </div>
                                      <div className="col-xl-6">
                                        <div className="form-group">
                                          <label>If Employed, what is the name of your Employer</label>
                                          <input type="text" className="form-control form-control-lg h-auto  rounded-lg" name placeholder="Employer Name"  />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-xl-6">
                                        <div className="form-group">
                                          <label>Industry Category</label>
                                          <select className="form-control form-control-lg h-auto  rounded-lg">
                                         
                                            <optgroup label="Healthcare Practitioners and Technical Occupations:">
                                              <option value="">  --</option>
                                              <option value="1">-  Chiropractor</option>
                                              <option value="2">-  Dentist</option>
                                              <option value="3">-  Dietitian or Nutritionist</option>
                                              <option value="4">-  Optometrist</option>
                                              <option value="5">-  Pharmacist</option>
                                              <option value="6">-  Physician</option>
                                              <option value="7">-  Physician Assistant</option>
                                              <option value="8">-  Podiatrist</option>
                                              <option value="9">-  Registered Nurse</option>
                                              <option value="10">-  Therapist</option>
                                              <option value="11">-  Veterinarian</option>
                                              <option value="12">-  Health Technologist or Technician</option>
                                              <option value="13">-  Other Healthcare Practitioners and Technical Occupation</option>
                                            </optgroup>
                                            <optgroup label="Healthcare Support Occupations:">
                                              <option value="14">-  Nursing, Psychiatric, or Home Health Aide</option>
                                              <option value="15">-  Occupational and Physical Therapist Assistant or Aide</option>
                                              <option value="16">-  Other Healthcare Support Occupation</option>
                                            </optgroup>
                                            <optgroup label="Business, Executive, Management, and Financial Occupations:">
                                              <option value="17">-  Chief Executive</option>
                                              <option value="18">-  General and Operations Manager</option>
                                              <option value="19">-  Advertising, Marketing, Promotions, Public Relations, and Sales Manager</option>
                                              <option value="20">-  Operations Specialties Manager (e.g., IT or HR Manager)</option>
                                              <option value="21">-  Construction Manager</option>
                                              <option value="22">-  Engineering Manager</option>
                                              <option value="23">-  Accountant, Auditor</option>
                                              <option value="24">-  Business Operations or Financial Specialist</option>
                                              <option value="25">-  Business Owner</option>
                                              <option value="26">-  Other Business, Executive, Management, Financial Occupation</option>
                                            </optgroup>
                                            <optgroup label="Architecture and Engineering Occupations:">
                                              <option value="27">-  Architect, Surveyor, or Cartographer</option>
                                              <option value="28">-  Engineer</option>
                                              <option value="29">-  Other Architecture and Engineering Occupation</option>
                                            </optgroup>
                                            <optgroup label="Education, Training, and Library Occupations:">
                                              <option value="30">-  Postsecondary Teacher (e.g., College Professor)</option>
                                              <option value="31">-  Primary, Secondary, or Special Education School Teacher</option>
                                              <option value="32">-  Other Teacher or Instructor</option>
                                              <option value="33">-  Other Education, Training, and Library Occupation</option>
                                            </optgroup>
                                            <optgroup label="Other Professional Occupations:">
                                              <option value="34">-  Arts, Design, Entertainment, Sports, and Media Occupations</option>
                                              <option value="35">-  Computer Specialist, Mathematical Science</option>
                                              <option value="36">-  Counselor, Social Worker, or Other Community and Social Service Specialist</option>
                                              <option value="37">-  Lawyer, Judge</option>
                                              <option value="38">-  Life Scientist (e.g., Animal, Food, Soil, or Biological Scientist, Zoologist)</option>
                                              <option value="39">-  Physical Scientist (e.g., Astronomer, Physicist, Chemist, Hydrologist)</option>
                                              <option value="40">-  Religious Worker (e.g., Clergy, Director of Religious Activities or Education)</option>
                                              <option value="41">-  Social Scientist and Related Worker</option>
                                              <option value="42">-  Other Professional Occupation</option>
                                            </optgroup>
                                            <optgroup label="Office and Administrative Support Occupations:">
                                              <option value="43">-  Supervisor of Administrative Support Workers</option>
                                              <option value="44">-  Financial Clerk</option>
                                              <option value="45">-  Secretary or Administrative Assistant</option>
                                              <option value="46">-  Material Recording, Scheduling, and Dispatching Worker</option>
                                              <option value="47">-  Other Office and Administrative Support Occupation</option>
                                            </optgroup>
                                            <optgroup label="Services Occupations:">
                                              <option value="48">-  Protective Service (e.g., Fire Fighting, Police Officer, Correctional Officer)</option>
                                              <option value="49">-  Chef or Head Cook</option>
                                              <option value="50">-  Cook or Food Preparation Worker</option>
                                              <option value="51">-  Food and Beverage Serving Worker (e.g., Bartender, Waiter, Waitress)</option>
                                              <option value="52">-  Building and Grounds Cleaning and Maintenance</option>
                                              <option value="53">-  Personal Care and Service (e.g., Hairdresser, Flight Attendant, Concierge)</option>
                                              <option value="54">-  Sales Supervisor, Retail Sales</option>
                                              <option value="55">-  Retail Sales Worker</option>
                                              <option value="56">-  Insurance Sales Agent</option>
                                              <option value="57">-  Sales Representative</option>
                                              <option value="58">-  Real Estate Sales Agent</option>
                                              <option value="59">-  Other Services Occupation</option>
                                            </optgroup>
                                            <optgroup label="Agriculture, Maintenance, Repair, and Skilled Crafts Occupations:">
                                              <option value="60">-  Construction and Extraction (e.g., Construction Laborer, Electrician)</option>
                                              <option value="61">-  Farming, Fishing, and Forestry</option>
                                              <option value="62">-  Installation, Maintenance, and Repair</option>
                                              <option value="63">-  Production Occupations</option>
                                              <option value="64">-  Other Agriculture, Maintenance, Repair, and Skilled Crafts Occupation</option>
                                            </optgroup>
                                            <optgroup label="Transportation Occupations:">
                                              <option value="65">-  Aircraft Pilot or Flight Engineer</option>
                                              <option value="66">-  Motor Vehicle Operator (e.g., Ambulance, Bus, Taxi, or Truck Driver)</option>
                                              <option value="67">-  Other Transportation Occupation</option>
                                            </optgroup>
                                            <optgroup label="Other Occupations:">
                                              <option value="68">-  Military</option>
                                              <option value="69">-  Homemaker</option>
                                              <option value="70">-  Other Occupation</option>
                                              <option value="71">-  Don't Know</option>
                                              <option value="72">-  Not Applicable</option>
                                            </optgroup>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-xl-6">
                                        <div className="form-group">
                                          <label>Job Title</label>
                                          <input type="text" className="form-control form-control-lg h-auto  rounded-lg" name placeholder="What is your Job Title"  />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-xl-6">
                                        <div className="form-group">
                                          <label>Total Income</label>
                                          <input type="text" className="form-control form-control-lg h-auto  rounded-lg" name placeholder="What is your Total Income"  />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="pb-5" data-wizard-type="step-content">
                                    <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left">
                                      <h3>Anti Money Laundering</h3>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-9 col-form-label">Would you be opening this account on behalf of a third party</label>
                                      <div className="col-3 col-form-label">
                                        <div className="checkbox-inline">
                                          <label className="checkbox checkbox-success">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Yes</label>
                                          <label className="checkbox checkbox-success ">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />No</label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-9 col-form-label">Are you a politically exposed person</label>
                                      <div className="col-3 col-form-label">
                                        <div className="checkbox-inline">
                                          <label className="checkbox checkbox-success">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Yes</label>
                                          <label className="checkbox checkbox-success ">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />No</label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-9 col-form-label">Are you an insider or senior director of a publicly traded company</label>
                                      <div className="col-3 col-form-label">
                                        <div className="checkbox-inline">
                                          <label className="checkbox checkbox-success">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Yes</label>
                                          <label className="checkbox checkbox-success ">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />No</label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-xl-9">
                                        <div className="form-group">
                                          <label>Upload your Identification <small> eg: driver's license, national identity card etc</small></label>
                                          <input type="file" className="form-control form-control-lg h-auto  rounded-lg" name placeholder="Upload ID"  />
                                        </div>
                                      </div>
                                    </div>
                                  </div></form>
                                <div className="pb-5" data-wizard-type="step-content">
                                  <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left">
                                    <h3 className="font-weight-bolder text-dark font-size-h2">Setup your personalized portfolio</h3>
                                  </div>
                                  <form className="form">
                                    <div className="form-group row">
                                      <label className="col-3 col-form-label">What is your main reason for investing</label>
                                      <div className="col-9 col-form-label">
                                        <div className="checkbox-inline">
                                          <label className="checkbox checkbox-success">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Home Purchase</label>
                                          <label className="checkbox checkbox-success ">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Retirement</label>
                                          <label className="checkbox checkbox-success ">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Emergency Fund</label>
                                          <label className="checkbox checkbox-success ">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Marriage</label>
                                          <label className="checkbox checkbox-success ">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Vacation</label>
                                          <label className="checkbox checkbox-success ">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Capital Growth</label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-5 col-form-label">How would you describe your investment knowledge</label>
                                      <div className="col-7 col-form-label">
                                        <div className="checkbox-inline">
                                          <label className="checkbox checkbox-success">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Limited</label>
                                          <label className="checkbox checkbox-success ">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Average</label>
                                          <label className="checkbox checkbox-success ">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Sophisticated</label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-5 col-form-label">What is your time Horizon<span style={{fontSize: '10px'}}>(you can withdraw funds at any time)</span></label>
                                      <div className="col-7 col-form-label">
                                        <div className="checkbox-inline">
                                          <label className="checkbox checkbox-primary   " title="hello">
                                            <input type="checkbox" title="hello" name="Checkboxes5" />
                                            <span />Short Term</label>
                                          <label className="checkbox checkbox-success ">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Medium Term</label>
                                          <label className="checkbox checkbox-success ">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Long Term</label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-5 col-form-label">How would you describe your Risk Tolerance</label>
                                      <div className="col-7 col-form-label">
                                        <div className="checkbox-inline">
                                          <label className="checkbox checkbox-success" data-tip data-for="lowrisk">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Low Risk</label>
                                            <ReactTooltip id="lowrisk" place="top" effect="solid">
                                             <center> <p>Low risk investments demonstrate a low volatility and are for investors who are willing</p> 
                                            <p>to accept lower returns for greater safety of capital and may include such investments  </p>
                                            <p>as Canada Savings Bonds, GICs and money market mutual funds.</p></center>
                                        </ReactTooltip>
                                        <label className="checkbox checkbox-success" data-tip data-for="lowrisk1">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Low to Medium Risk</label>
                                            <ReactTooltip id="lowrisk1" place="top" effect="solid">
                                             <center> <p>Low to Medium risk investments demonstrate a low to medium volatility but a higher </p> 
                                            <p>volatility than those described above and may include bond or balanced funds  </p>
                                            </center>
                                        </ReactTooltip>
                                        <label className="checkbox checkbox-success" data-tip data-for="lowrisk2">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Medium Risk</label>
                                            <ReactTooltip id="lowrisk2" place="top" effect="solid">
                                             <center> <p>Medium risk investments demonstrate a medium volatility and are for investors that are looking for moderate growth  </p> 
                                            <p>over a longer period of time and may include Canadian dividend, Canadian equity, U.S. equity</p> <p> and certain international equity funds. </p>
                                            </center>
                                        </ReactTooltip>
                                        <label className="checkbox checkbox-success" data-tip data-for="lowrisk3">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />High Risk</label>
                                            <ReactTooltip id="lowrisk3" place="top" effect="solid">
                                             <center> <p>High risk investments demonstrate a high volatility and are for investors who are growth oriented and are willing to accept</p> 
                                            <p>significant short term fluctuations in portfolio value in exchange for potentially higher long term returns and </p>
                                            <p>may include labor-sponsored venture capital funds  or funds that invest in specific market  </p>
                                            <p>sectors or geographic areas such as emerging markets, science and technology,</p>
                                            <p>or funds that engage in speculative trading strategies, including hedge funds </p>
                                            <p>that invest in derivatives, short sell or use leverage.</p>
                                            </center>
                                        </ReactTooltip>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-5 col-form-label">What is your Investment Objective</label>
                                      <div className="col-7 col-form-label">
                                        <div className="checkbox-inline">
                                        <label className="checkbox checkbox-success" data-tip data-for="lowrisk41">
                                            </label>
                                            <ReactTooltip id="lowrisk41" place="top" effect="solid">
                                             <center> <p>Your objective is to generate current income from your investments and you are less concerned with</p> 
                                            </center>
                                        </ReactTooltip>
                                        <label className="checkbox checkbox-success" data-tip data-for="lowrisk4">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Income</label>
                                            <ReactTooltip id="lowrisk4" place="top" effect="solid">
                                             <center> <p>Your objective is to generate current income from your investments and you are less concerned with</p> 
                                            <p> capital appreciation. Investments that will satisfy this objective include fixed income </p>
                                            <p>investments such as funds that invest in bond or money market instruments. </p>
                                            </center>
                                        </ReactTooltip>
                                        <label className="checkbox checkbox-success" data-tip data-for="lowrisk5">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Growth</label>
                                            <ReactTooltip id="lowrisk5" place="top" effect="solid">
                                             <center> <p>Your objective is capital appreciation and current income from investments is not a requirement.</p> 
                                            <p> This may lead you to hold a relatively high proportion of investments in equities </p>
                                            <p>if you also have a higher risk tolerance and long term time horizon.</p>
                                            </center>
                                        </ReactTooltip>
                                        <label className="checkbox checkbox-success" data-tip data-for="lowrisk6">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Balanced</label>
                                            <ReactTooltip id="lowrisk6" place="top" effect="solid">
                                             <center> <p>Your objective is a combination of income and growth.</p> 
                                            </center>
                                        </ReactTooltip>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-5 col-form-label">How much do you have saved</label>
                                      <div className="col-7 col-form-label">
                                        <input type="text" className="form-control form-control-lg h-auto  rounded-lg" name placeholder="250,000.00"  />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-5 col-form-label">What is the value of your property and assets</label>
                                      <div className="col-7 col-form-label">
                                        <input type="text" className="form-control form-control-lg h-auto  rounded-lg" name placeholder="250,000.00"  />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-5 col-form-label">What is the value of your debts</label>
                                      <div className="col-7 col-form-label">
                                        <input type="text" className="form-control form-control-lg h-auto  rounded-lg" name placeholder="250,000.00"  />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-4 col-form-label">How many years do you have with the following:</label>
                                      <div className="col-2 col-form-label">
                                        <label>Stocks</label>
                                        <input type="number" className="form-control form-control-lg h-auto  rounded-lg" name/>
                                      </div>
                                      <div className="col-2 col-form-label">
                                        <label>Bonds</label>
                                        <input type="number" className="form-control form-control-lg h-auto  rounded-lg" name/>
                                      </div>
                                      <div className="col-2 col-form-label">
                                        <label>Mutual Funds</label>
                                        <input type="number" className="form-control form-control-lg h-auto  rounded-lg" name />
                                      </div>
                                      <div className="col-2 col-form-label">
                                        <label>ETF</label>
                                        <input type="number" className="form-control form-control-lg h-auto  rounded-lg" name   />
                                      </div>
                                    </div>
                                  </form>
                                </div>
                                <div className="pb-5" data-wizard-type="step-content">
                                  <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left">
                                    <h3 className="font-weight-bolder text-dark font-size-h2">Setup your Account</h3>
                                  </div>
                                  <form className="form">
                                    <div className="form-group row">
                                      <label className="col-9 col-form-label">Do you have an existing investment account you would like to transfer</label>
                                      <div className="col-12 col-form-label">
                                        <div className="checkbox-inline">
                                          <label className="checkbox checkbox-success">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Yes, i'd like to  transfer an existing investment account</label>
                                          <label className="checkbox checkbox-success ">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />No, i'll start with a new piggyalpha account</label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-9 col-form-label">What type of account would you like to open</label>
                                      <div className="col-9 col-form-label">
                                        <label className="form-label" style={{fontSize: '10px'}}>For investing</label>
                                        <div className="checkbox-inline">
                                          <label className="checkbox checkbox-success">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />RRSP</label>
                                          <label className="checkbox checkbox-success ">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />TFSA</label>
                                          <label className="checkbox checkbox-success ">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Non-registered</label>
                                          <label className="checkbox checkbox-success ">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />Joint</label>
                                          <label className="checkbox checkbox-success ">
                                            <input type="checkbox" name="Checkboxes5" />
                                            <span />RESP</label>
                                        </div>
                                      </div>
                                    </div>
                                    <a type="button" href="transfer1" className="btn btn-primary font-weight-bolder font-size-h6 pr-8 pl-6 py-4 my-3 mr-3">
                                      Transfer</a>
                                  </form>
                                </div>
                                <div className="pb-5" data-wizard-type="step-content">
                                  <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left">
                                    <h3 className="font-weight-bolder text-dark font-size-h2">Preview</h3>
                                    <div className="form-group">
                                      <label className="col-9 col-form-label">Personal Information</label>
                                      <span className="fa fa-pencil-alt" style={{float: 'right'}} />
                                    </div>
                                  </div>
                                  <div className="separator separator-solid my-7" />
                                  <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left">
                                    <div className="form-group">
                                      <label className="col-9 col-form-label">Employment Information</label>
                                      <span className="fa fa-pencil-alt" style={{float: 'right'}} />
                                    </div>
                                  </div>
                                  <div className="separator separator-solid my-7" />
                                  <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left">
                                    <div className="form-group">
                                      <label className="col-9 col-form-label">Anti-Money Laundering</label>
                                      <span className="fa fa-pencil-alt" style={{float: 'right'}} />
                                    </div>
                                  </div>
                                  <div className="separator separator-solid my-7" />
                                  <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left">
                                    <div className="form-group">
                                      <label className="col-9 col-form-label">Client Investor Profile</label>
                                      <span className="fa fa-pencil-alt" style={{float: 'right'}} />
                                    </div>
                                  </div>
                                  <div className="separator separator-solid my-7" />
                                  <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left">
                                    <div className="form-group">
                                      <label className="col-9 col-form-label">Accounts</label>
                                      <span className="fa fa-pencil-alt" style={{float: 'right'}} />
                                    </div>
                                  </div>
                                  <div className="separator separator-solid my-7" />
                                  <div className="mt-10 text-center">
                                    <a href="0#">Review Your agreements</a>
                                    <br />
                                    <p className="opacity-70 mr-4 text-muted font-weight-bold" style={{marginBottom: '-2px', fontSize: '13px'}}>By checking the box below, you acknowledge that you have read and agreed to the terms and </p>
                                    <p className="opacity-70 mr-4 text-muted font-weight-bold" style={{fontSize: '13px'}}>conditions and agree to use electronic records and signatures. </p>
                                    <label className="checkbox checkbox-success text-muted font-weight-bold">
                                      <input type="checkbox" name="Checkboxes5" />
                                      <span />&nbsp; I agree to the Client Agreement and as of today, I certify that the information I have provided in the following is correct. </label>
                                  </div>
                                </div>
                                <div>
                                  <div className="d-flex justify-content-between pt-7">
                                    <div className="mr-2">
                                      <button type="button" className="btn btn-light-primary font-weight-bolder font-size-h6 pr-8 pl-6 py-4 my-3 mr-3" data-wizard-type="action-prev">
                                        <span className="svg-icon svg-icon-md mr-2">
                                          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                              <polygon points="0 0 24 0 24 24 0 24" />
                                              <rect fill="#000000" opacity="0.3" transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000)" x={14} y={7} width={2} height={10} rx={1} />
                                              <path d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997)" />
                                            </g>
                                          </svg>
                                        </span>Previous</button>
                                    </div>
                                    <div>
                                      <button type="button" className="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3" data-wizard-type="action-submit" data-toggle="modal" data-target="#exampleModalScrollable">Submit
                                        <span className="svg-icon svg-icon-md ml-2">
                                          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                              <polygon points="0 0 24 0 24 24 0 24" />
                                              <rect fill="#000000" opacity="0.3" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width={2} height={9} rx={1} />
                                              <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                            </g>
                                          </svg>
                                        </span></button>
                                      <button type="button" className="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3" onClick={handleWizardClick}>Next
                                        <span className="svg-icon svg-icon-md ml-2">
                                          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                              <polygon points="0 0 24 0 24 24 0 24" />
                                              <rect fill="#000000" opacity="0.3" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width={2} height={9} rx={1} />
                                              <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                            </g>
                                          </svg>
                                        </span></button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal fade" id="exampleModalScrollable" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-default" role="document">
                  <div className="modal-content">
                    <div className="modal-body" style={{height: 'auto', width: 'auto'}}>
                      <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left">
                        <div className="d-flex flex-center">
                          <a href="0#">
                            <img src="assets/media/favicon.png" className="max-h-75px" alt="" />
                          </a>
                        </div>
                        <div className style={{padding: '35px', textAlign: 'center'}}>
                          <h3 className="font-weight-bold text-dark font-size-h2 text-center">Congratulations</h3>
                          <p className="opacity-70" style={{marginBottom: '-2px', fontSize: '13px'}}>You have successfully setup your account profile, we just need to start building out your personalized portfolio so we can start growing your money and fulfilling your goals.</p>
                          <br />
                          <center><a type="button" href="funding" className="btn btn-primary text-center font-weight-bold">Fund your account</a></center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
<div>
              <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Missing fields</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please enter all information correctly before proceeding.</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
            </div>
         

    );
};

export default Wizard;
